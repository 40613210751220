// Use LAN for development
// const API_URL = 'http://192.168.2.100:8000/';
const API_URL = "https://kast-backend.herokuapp.com/";

export const config = {
  statusBar: "light-content",
  updateRequired: false,
  api: {
    url: API_URL,
    token: process.env.REACT_APP_HAS_KAST_TOKEN,
  },
  hasoffers: {
    url: process.env.REACT_APP_HAS_OFFERS_URL,
    networkId: "espire",
    networkToken: process.env.REACT_APP_HAS_OFFERS_TOKEN,
    dashboard: "https://influence.espireads.com/",
  },
  company: {
    name: "Espire",
    // image: null,
  },
  contact: {
    general: "info@espireads.com",
    billing: "accounting@espireads.com",
    tech: "support@espireads.com",
  },
  links: {
    register: "https://influence.espireads.com/signup",
    dashboard: "https://influence.espireads.com/",
    website: "https://www.espireads.com",
  },
  resources: {
    "Download W9 form": "https://www.irs.gov/pub/irs-pdf/fw9.pdf",
    "Download W8BEN (outside US) form":
      "https://www.irs.gov/pub/irs-pdf/fw8ben.pdf",
  },
  offers: {
    latestCategories: ["CPA", "NON INCENT"],
    getLinks: (offer, affiliateId) => {
      let links = [];
      // Custom landing pages
      if (offer.domain_links != null) {
        offer.domain_links.forEach((domain) => {
          if (domain.link != null && domain.direct != null) {
            links.push({
              title: domain.direct ? "Direct Domain" : "Landing Page",
              link: domain.link,
            });
          } else links.push({ title: "Landing Page", link: domain });
        });
      }
      // Generic download/click-through landing page
      links.push({
        title: "SmartLink",
        link: `https://www.${offer.trackingDomain}/${affiliateId}/${offer.id}`,
      });
      return links;
    },
  },
  creativeLinks: {
    source: (filename) =>
      `https://kast-static.s3.amazonaws.com/creatives/espire/${filename}`,
    thumbnail: (filename) =>
      `https://kast-static.s3.amazonaws.com/creatives/espire/preview/${filename}`,
  },
  features: {
    cashOut: true,
    contests: true,
    creatives: true,
    leaderboards: true,
    bonus: true,
    marketplace: false,
  },
  bonusLevels: [
    { minimum: 250, bonus: 2 },
    { minimum: 500, bonus: 3 },
    { minimum: 1000, bonus: 5 },
    { minimum: 2500, bonus: 7 },
    { minimum: 5000, bonus: 10 },
  ],
  notifications: [
    { type: "announcements", initial: true, slim: true },
    { type: "contests", initial: true },
    { type: "conversions", initial: false, slim: true },
    { type: "marketplace", initial: true },
    { type: "offers", initial: true },
    { type: "billing", initial: true },
    { type: "support", initial: true },
  ],
};
