import * as Sentry from "@sentry/browser";

export const underscoreToCamelCase = (s) =>
  (
    s.charAt(0).toLowerCase() +
    s
      .replace(/_([a-z])/g, function (g) {
        return g[1].toUpperCase();
      })
      .slice(1)
  ).toString();

export const toCamel = (o) => {
  if (o == null) {
    return o;
  }

  let newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === "object") {
        value = toCamel(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = underscoreToCamelCase(origKey);
        value = o[origKey];
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = toCamel(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
};
export const camelCaseToUnderscore = (s) =>
  s.replace(/([A-Z])/g, (g) => `_${g.toLowerCase()}`);
export const toUnderscore = (o) => {
  if (o == null) {
    return o;
  }

  let newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === "object") {
        value = toUnderscore(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = camelCaseToUnderscore(origKey);
        value = o[origKey];
        if (
          value instanceof Array ||
          (value != null && value.constructor === Object)
        ) {
          value = toUnderscore(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
};

export const cleanIncludesAffiliateUser = (data) => {
  let affiliates = [];
  Object.values(data).map((val) => {
    affiliates.push({
      AffiliateUser: val.AffiliateUser[Object.keys(val.AffiliateUser)[0]],
      Affiliate: val.Affiliate,
    });
  });
  return affiliates;
};

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const searchAffiliateUserByName = (value, list) => {
  let keyword = capitalizeFirstLetter(value);
  let newData = [];
  list.filter((item) => {
    let firstName = capitalizeFirstLetter(item.AffiliateUser.first_name);
    firstName.includes(keyword) && newData.push(item);
  });
  return newData;
};

export const format_number_commas = (num) =>
  num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

export const formatCurrency = (number) =>
  new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
    number
  );

export const reportToSentry = ([type, error, tag]) => {
  Sentry.configureScope((scope) => {
    type === "api" && scope.setTag("api", tag);
    Sentry.captureException(new Error(error));
  });
};

export const percentageOf = (current, outOf) => {
  let percentage = (current * 100) / outOf;
  return percentage.toFixed(0);
};
export const percent = (partialValue) => {
  let num = parseFloat(partialValue).toFixed(2);
  return (num * 100) / 100;
};

export const convertColumnType = (keys) => {
  if (
    keys === "payout" ||
    keys === "cpm" ||
    keys === "cpc" ||
    keys === "cpa" ||
    keys === "revenue" ||
    keys === "profit" ||
    keys === "rpm" ||
    keys === "rpc" ||
    keys === "rpa"
  ) {
    return "cash";
  } else if (keys === "ctr") {
    return "percent";
  } else if (keys === "affiliate_id") {
    return "id";
  } else if (keys === "date") {
    return "date";
  } else if (keys === "company") {
    return "text";
  } else {
    return keys;
  }
};
