import React, { useState } from "react";
import { Table, Input, Button, Space } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import {
  formatCurrency,
  format_number_commas,
  capitalizeFirstLetter,
  percent,
  convertColumnType,
} from "../../../util";

const ReportTable = ({ data, isLoading, error }) => {
  const newData = [];
  isLoading ||
    data.data.map((key) => newData.push({ ...key.Affiliate, ...key.Stat }));
  const columns = [];

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  let searchInput = null;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div key={dataIndex} style={{ padding: 8 }}>
          <Input
            ref={(input) => {
              searchInput = input;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          key={dataIndex}
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  if (!isLoading) {
    Object.keys(newData[0]).map(async (keys) => {
      console.log("keys", keys);
      let type = convertColumnType(keys);
      if (keys === "company") {
        columns.push({
          defaultFilteredValue: ["hour"],
          title: capitalizeFirstLetter(keys),
          dataIndex: keys,
          ...getColumnSearchProps(keys),
        });
      } else {
        columns.push({
          defaultFilteredValue: ["hour"],
          sorter: (a, b) => a[keys] - b[keys],
          filtered: keys === "hour" ? true : false,
          title:
            keys.length < 4
              ? keys.toUpperCase()
              : keys === "affiliate_id"
              ? "ID"
              : capitalizeFirstLetter(keys),
          dataIndex: keys,
          render: (text) => {
            console.log("text", type, text);
            return (
              <div>
                {type === "cash"
                  ? formatCurrency(text)
                  : type === "percent"
                  ? `${percent(text)}%`
                  : type === "id"
                  ? text
                  : text.includes("-")
                  ? text
                  : format_number_commas(text)}
              </div>
            );
          },
        });
      }
    });
  }
  return (
    <div>
      {data && (
        <Table
          scroll={{ x: true }}
          loading={isLoading}
          size="small"
          rowKey={(record) => record.affiliate_id}
          columns={columns}
          dataSource={isLoading ? [] : newData}
        />
      )}
    </div>
  );
};

export default ReportTable;
