import moment from "moment";

const dayFormatter = (date, amount, format) => {
  return moment(date ? date : moment())
    .subtract(amount ? amount : 0, format ? format : "days")
    .format("YYYY-MM-DD");
};
const mainFormatter = (date, amount, format, startOf) => {
  if (startOf) {
    return moment(date ? date : moment())
      .subtract(amount ? amount : 0, format)
      .startOf(format)
      .format("YYYY-MM-DD");
  } else {
    return moment(date ? date : moment())
      .subtract(amount ? amount : 0, format)
      .endOf(format)
      .format("YYYY-MM-DD");
  }
};
const Today = dayFormatter(null, 0, null);
const Yesterday = dayFormatter(null, 1, null);
const prevYesterday = dayFormatter(Yesterday, 1, null);

const SevenDaysAgo = dayFormatter(null, 6, null);
const Prev_SevenDaysAgo = dayFormatter(null, 13, null);

const Prev_fourTeenDaysAgoEnd = dayFormatter(null, 7, null);

const LastWeekStart = mainFormatter(null, 1, "weeks", true);
const LastWeekEnds = mainFormatter(null, 1, "weeks", false);

const Prev_LastWeekStart = mainFormatter(null, 2, "weeks", true);
const Prev_LastWeekEnds = mainFormatter(null, 2, "weeks", false);

const StartOfMonth = moment().startOf("month").format("YYYY-MM-DD");

const Prev_StartOfMonth = mainFormatter(null, 1, "month", true);
const Prev_EndOfMonth = dayFormatter(Today, 1, "month");

const Last30Days = dayFormatter(null, 30, "days");
const Prev_Last30Days = dayFormatter(Last30Days, 30, "days");

const Prev_Last30DaysEnd = dayFormatter(Yesterday, 30, "days");
const StartPreviousMonth = mainFormatter(null, 1, "month", true);
const EndPreviousMonth = mainFormatter(null, 1, "month", false);

const Prev_StartPreviousMonth = mainFormatter(null, 2, "month", true);
const Prev_EndPreviousMonth = mainFormatter(null, 2, "month", false);

const Dates = [
  {
    value: "Today",
    current: { start_date: Today, end_date: Today },
    previous: { start_date: Yesterday, end_date: Yesterday },
  },
  {
    value: "Yesterday",
    current: { start_date: Yesterday, end_date: Yesterday },
    previous: { start_date: prevYesterday, end_date: prevYesterday },
  },
  {
    value: "Last 7 days",
    current: { start_date: SevenDaysAgo, end_date: Today },
    previous: {
      start_date: Prev_SevenDaysAgo,
      end_date: Prev_fourTeenDaysAgoEnd,
    },
  },
  {
    value: "Previous week(Sun - Sat)",
    current: { start_date: LastWeekStart, end_date: LastWeekEnds },
    previous: {
      start_date: Prev_LastWeekStart,
      end_date: Prev_LastWeekEnds,
    },
  },
  {
    value: "Month to date",
    current: { start_date: StartOfMonth, end_date: Today },
    previous: {
      start_date: Prev_StartOfMonth,
      end_date: Prev_EndOfMonth,
    },
  },
  {
    value: "Last 30 days",
    current: { start_date: Last30Days, end_date: Yesterday },
    previous: {
      start_date: Prev_Last30Days,
      end_date: Prev_Last30DaysEnd,
    },
  },
  {
    value: "Previous month",
    current: { start_date: StartPreviousMonth, end_date: EndPreviousMonth },
    previous: {
      start_date: Prev_StartPreviousMonth,
      end_date: Prev_EndPreviousMonth,
    },
  },
];

export default Dates;
