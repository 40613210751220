import { config } from "../config";
import { toUnderscore } from "../util";
import * as Sentry from "@sentry/browser";

class APIError extends Error {
  constructor(message, status, response, attempt = 1) {
    let fullMessage = `${message} (status: ${status})`;
    super(fullMessage);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(fullMessage).stack;
      Sentry.addBreadcrumb("API Error", "error", {
        response,
        stack: this.stack,
        attempt,
      });
    }
  }
}

class BackendAPI {
  constructor(options) {
    options = options || {};
    this.apiEndpoint = options.apiEndpoint || config.api.url;
    this.user = options.user || {};
    this.authStore = options.authStore;
    this.delay = options.delay || 500;
  }

  /**
   * Notify the app API that a user logged into the app so it can
   * register the user's hash for subsequent calls
   * @returns {Promise<void>}
   */
  async registerLoginWithServer(userData, managerId) {
    try {
      return await this._request(
        `${this.apiEndpoint}auth/login/`,
        toUnderscore({
          ...userData,
          managerId,
        }),
        {
          method: "POST",
          authOverride: `Network ${config.api.token}`,
        }
      );
    } catch (error) {
      Sentry.captureException(error, {
        extra: { message: error != null ? error.message : null },
      });
      return {
        success: false,
        error: `Unable to authenticate with server! Please try again.`,
      };
    }
  }

  /**
   * Retrieves the user's profile
   * @returns {Promise<*>}
   */
  async fetchProfile(user) {
    try {
      return await this._request(
        `${this.apiEndpoint}auth/profile/`,
        undefined,
        {},
        1,
        user
      );
    } catch (error) {
      Sentry.captureException(error);
      return {
        success: false,
        error:
          "An error occurred attempting to fetch your user profile. Please try again.",
      };
    }
  }

  async updateProfile(profileChanges) {
    return await this._request(
      `${this.apiEndpoint}auth/profile/`,
      profileChanges,
      { method: "POST" }
    ).catch((error) => {
      Sentry.captureException(error);
      return {
        success: false,
        error:
          "An error occurred attempting to update your profile! Please try again.",
      };
    });
  }

  /**
   * Retrieves marketplace info
   */
  async fetchMarketplaceInfo() {
    try {
      return await this._request(`${this.apiEndpoint}marketplace/`);
    } catch (error) {
      Sentry.captureException(error);
      return {
        success: false,
        error:
          "An error occurred attempting to fetch your user profile. Please try again.",
      };
    }
  }

  /**
   * Update marketplace account
   */
  async updateMarketplaceAccount(account) {
    try {
      return await this._request(
        `${this.apiEndpoint}marketplace/`,
        { account },
        { method: "POST" }
      );
    } catch (error) {
      Sentry.captureException(error);
      return {
        success: false,
        error:
          "An error occurred attempting to update your marketplace account! Please try again.",
      };
    }
  }

  /**
   * Browse marketplace accounts
   */
  async getAccounts(filters) {
    try {
      return await this._request(
        `${this.apiEndpoint}marketplace/browse/`,
        filters,
        { method: "POST" }
      );
    } catch (error) {
      Sentry.captureException(error);
      return {
        success: false,
        error:
          "An error occurred attempting to fetch accounts! Please try again.",
      };
    }
  }

  /**
   * Send details for a post an advertiser would like to purchase from an affiliate account
   * @param post Raw post data
   * @returns Validated post data ready to be applied to the model
   */
  async createMarketplacePost(post) {
    try {
      return await this._request(
        `${this.apiEndpoint}marketplace/purchase/post/`,
        { post },
        { method: "POST" }
      );
    } catch (error) {
      Sentry.captureException(error);
      return {
        success: false,
        error:
          "An error occurred attempting to process your post! Please try again.",
      };
    }
  }

  /**
   * Register the users push notification token with the backend.
   * @param settings Settings to send to the API (token, etc.)
   * @returns {Promise<*>}
   */
  async updateNotificationSettings(settings) {
    try {
      // TODO: Remove await in every this._request call
      // TODO: Remove try/catch and switch to this._request(...).catch(error => {})
      return await this._request(
        `${this.apiEndpoint}notifications/`,
        settings || {},
        {
          method: "POST",
        }
      );
    } catch (error) {
      Sentry.captureException(error);
      return {
        success: false,
        error: `An error occurred attempting to update your notification settings. Please try again.`,
      };
    }
  }

  async getNotificationData(uuid) {
    try {
      return await this._request(`${this.apiEndpoint}notifications/${uuid}/`);
    } catch (error) {
      Sentry.captureException(error);
      return {
        success: false,
        error:
          "An error occurred attempting to fetch the notification data. Please try again.",
      };
    }
  }

  /**
   * Resets the user's unread notification count on the backend to 0 for future notifications
   * @returns {Promise<void>}
   */
  async resetUnreadNotificationCount() {
    try {
      return await this._request(
        `${this.apiEndpoint}notifications/reset/`,
        undefined,
        { method: "POST" }
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  /**
   * Fetch revenue totals for the current user.
   * @returns {Promise<*>}
   */
  async getEarnings() {
    try {
      return {
        earnings: await this._request(`${this.apiEndpoint}stats/revenue/`),
        success: true,
      };
    } catch (error) {
      Sentry.captureException(error);
      return {
        earnings: null,
        success: false,
        error: "Unable to refresh earnings! Please try again.",
      };
    }
  }

  /**
   * Fetch stats (clicks, impressions, payout) for the current user.
   * @returns {Promise<*>}
   */
  async getStats() {
    try {
      return {
        stats: await this._request(`${this.apiEndpoint}stats/performance/`),
        success: true,
      };
    } catch (error) {
      Sentry.captureException(error);
      return {
        stats: null,
        success: false,
        error: "Unable to refresh stats! Please try again.",
      };
    }
  }

  /**
   * Fetch account balance and all user invoices.
   * @returns {Promise<*>}
   */
  async getAccountBalanceAndInvoices() {
    try {
      let result = await this._request(`${this.apiEndpoint}billing/`);
      result.success = true;
      return result;
    } catch (error) {
      Sentry.captureException(error);
      return {
        balance: null,
        invoices: [],
        success: false,
        error:
          "Unable to fetch account balance and invoices! Please try again.",
      };
    }
  }

  async requestCashOut(affiliate, amount, invoices) {
    try {
      return await this._request(
        `${this.apiEndpoint}billing/cashout/`,
        {
          affiliate,
          amount,
          invoices,
        },
        {
          method: "POST",
        }
      );
    } catch (error) {
      Sentry.captureException(error);
      return {
        success: false,
        error: "Unable to request cash out! Please try again.",
      };
    }
  }

  /**
   * Fetch current contest and various leaderboard rankings.
   * @returns {Promise<*>}
   */
  async getLeaderboardAndContests() {
    try {
      let response = await this._request(`${this.apiEndpoint}contests/`);
      return {
        rankings: response.data,
        activeContests: response.contests,
        success: true,
      };
    } catch (error) {
      Sentry.captureException(error);
      return {
        rankings: null,
        activeContests: null,
        success: false,
        error: "Unable to load leaderboard and contests! Please try again.",
      };
    }
  }

  /**
   * Fetches chat data for the current user.
   *
   * - If threadId is supplied, messages for that specific thread (if found) will be returned.
   * - If the user is a manager and no threadId is provided, a list of chat threads will be returned.
   * - If the user is an affiliate, they will only have one thread with their manager so a list of messages
   * is returned instead.
   * @param threadId Optional ID of a thread to retrieve messages for
   * @returns {Promise<*>}
   */
  async getChat(threadId) {
    const params =
      threadId !== undefined && threadId !== null ? { threadId } : {};
    try {
      return await this._request(`${this.apiEndpoint}chat/messages/`, params);
    } catch (error) {
      Sentry.captureException(error);
      return {
        success: false,
        error:
          "Unable to refresh chat! Please check your internet connection and try again.",
      };
    }
  }

  /**
   * Send one or more chat messages
   * @param threadId Thread to send messages to
   * @param messages Array of messages to send
   * @returns {Promise<*>}
   */
  async sendChatMessages(threadId, messages) {
    try {
      return await this._request(
        `${this.apiEndpoint}chat/messages/`,
        threadId ? { threadId, messages } : { messages },
        { method: "POST" }
      );
    } catch (error) {
      Sentry.captureException(error);
      return {
        success: false,
        error: "Unable to send message!",
        errorMessages: messages,
      };
    }
  }

  async createManagerChatThread(affiliateId) {
    try {
      return await this._request(`${this.apiEndpoint}chat/manager/create/`, {
        affiliateId,
      });
    } catch (error) {
      Sentry.captureException(error);
      return {
        success: false,
        error:
          "An unexpected error occurred! " +
          "Please try again later or contact tech support.",
      };
    }
  }

  /**
   * Fetch all available offers for the current user.
   * @returns {Promise<*>}
   */
  async getOffers() {
    try {
      let response = await this._request(`${this.apiEndpoint}offers/`);
      response.success = true;
      return response;
    } catch (error) {
      Sentry.captureException(error);
      return {
        success: false,
        error: "Unable to load offers! Please try again.",
      };
    }
  }

  /**
   * Request access to an offer from their manager
   * @param offer_id ID of the offer to request access to
   * @param type 'offer' or 'group'
   * @returns {Promise<*>}
   */
  async requestAccessToOffer(offer_id, type) {
    try {
      return await this._request(
        `${this.apiEndpoint}offers/access/`,
        { offer_id, type },
        {
          method: "POST",
        }
      );
    } catch (error) {
      Sentry.captureException(error);
      return {
        success: false,
        error: `Unable to request access to offer! Please try again.`,
      };
    }
  }

  /**
   * Upload a screenshot to an offer or group
   * @param uri Image URI
   * @param idAttr offer_id or group_id
   * @param id ID of the offer/group
   */
  async uploadScreenshot(uri, idAttr, id) {
    try {
      // Build form data
      const name = uri.substring(uri.lastIndexOf("/") + 1);
      const body = new FormData();
      body.append("image", {
        name,
        uri,
        type:
          name.substring(name.lastIndexOf(".") + 1).toLowerCase() === "png"
            ? "image/png"
            : "image/jpeg",
      });
      body.append(idAttr, id);

      // Perform request
      let resp = await fetch(`${this.apiEndpoint}offers/screenshot/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `User ${this.authToken}`,
        },
        body,
      });

      // Process response
      if (resp.status !== 200) {
        const response = await resp.json();
        if (resp.status === 401 && this.authStore != null) {
          if (response.detail === "Invalid hash.") {
            // Force the user to log back in and refresh their hash
            this.authStore.logout();
            throw new APIError("Invalid hash.", resp.status, response);
          }
        }
        throw new APIError(
          "Wrong status code received!",
          resp.status,
          response
        );
      }
      return await resp.json();
    } catch (error) {
      Sentry.captureException(error);
      return {
        success: false,
        error: "Unable to upload screenshot! Please try again.",
      };
    }
  }

  /**
   * Request a password reset for an email (a confirmation link will be sent to the email to continue within the dash)
   * @param email Email requesting a reset
   * @returns {Promise<*>}
   */
  async requestPasswordReset(email) {
    try {
      const result = await this._request(
        `${this.apiEndpoint}auth/reset/`,
        { email },
        {
          method: "POST",
          authOverride: `Network ${config.api.token}`,
        }
      );
      return result.success;
    } catch (error) {
      Sentry.captureException(error);
      return false;
    }
  }

  async _request(url, params = undefined, options = {}, attempt = 1, user) {
    // Prepare API request
    let requestOptions = {
      method: options.method || "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: options.authOverride || `User ${user.hash}`,
      },
    };

    // Handle differences between GET/POST
    let endpoint = url;
    if (options.method === "POST") {
      requestOptions.body = JSON.stringify(params);
    } else if (params !== undefined) {
      endpoint = `${url}?${this._serialize(params)}`;
    } else {
      endpoint = url;
    }

    // Perform request
    let resp = await fetch(endpoint, requestOptions);
    if (resp.status !== 200) {
      // Retry any request up to 4 times that wasn't an auth or server error
      // (mainly because Heroku periodically returns 404 app not found for an unknown reason)
      if (![401, 500].includes(resp.status) && attempt < 4) {
        // Log breadcrumb for previous attempt
        Sentry.addBreadcrumb("Retrying failed request", "backend", {
          url,
          params,
          options,
          status: resp.status,
          attempt,
        });
        // Delay retry to prevent overloading server
        await new Promise((resolve) => setTimeout(resolve, this.delay));
        return this._request(url, params, options, attempt + 1);
      }

      let response,
        copy = resp.clone();
      try {
        response = await resp.json();
      } catch (error) {
        response = await copy.text();
        Sentry.captureException(error, {
          response,
          url,
          params,
          options,
          status: resp.status,
          attempt,
        });
      }

      if (resp.status === 401 && this.authStore != null) {
        if (response.detail === "Invalid hash.") {
          // Force the user to log back in and refresh their hash
          this.authStore.logout();
          throw new APIError("Invalid hash.", resp.status, response, attempt);
        }
      }
      throw new APIError(
        "Wrong status code received!",
        resp.status,
        response,
        attempt
      );
    }
    return await resp.json();
  }

  /**
   * Serializes an Object to be used with the API.
   *
   * @access private
   * @param {Object} obj - Object to serialize.
   * @param {String} prefix - Parent key name (mostly used recursively).
   * @returns {String} A serialized string.
   */
  _serialize(obj, prefix) {
    var str = [],
      p;
    for (p in obj)
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push(
          v !== null && typeof v === "object"
            ? this._serialize(v, k)
            : encodeURIComponent(k) + "=" + encodeURIComponent(v)
        );
      }
    return str.join("&");
  }
}
let Backend = new BackendAPI();
export default Backend;
