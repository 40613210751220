import { put } from "redux-saga/effects";
import { SET_AUTH } from "../actions";
import "firebase/auth";

export function* authSaga(action) {
  try {
    yield put({
      type: SET_AUTH,
    });
  } catch (error) {
    yield put({
      type: SET_AUTH,
    });
  }
}
