export const register_form_items = [
  {
    type: "account",
    name: "company",
    label: "Company / Name",
    rules: {
      required: true,
      message: "Please input your company / name!",
    },
  },
  {
    type: "account",
    name: "address1",
    label: "Address 1",
    rules: {
      required: true,
      message: "Please input your address!",
    },
  },
  {
    type: "account",
    name: "city",
    label: "City",
    rules: {
      required: true,
      message: "Please input your city!",
    },
  },
  {
    type: "account",
    name: "country",
    label: "Country",
    rules: {
      required: true,
      message: "Please input your country!",
    },
  },
  {
    type: "account",
    name: "zipcode",
    label: "Zip Code",
    rules: {
      required: true,
      message: "Please input your zip code!",
    },
  },
  {
    type: "account",
    name: "phone",
    label: "Phone",
    rules: {
      required: true,
      message: "Please input your phone!",
    },
  },
  {
    type: "user",
    name: "email",
    label: "E-mail Address",
    rules: {
      required: true,
      message: "Please input your E-mail!",
    },
  },
  {
    type: "user",
    name: "password",
    label: "Password",
    rules: {
      required: true,
      message: "Please input your password!",
    },
  },
  {
    type: "user",
    name: "password_confirmation",
    label: "Confirm Password",
    rules: {
      required: true,
      message: "Please confirm your password!",
    },
  },
  {
    type: "user",
    name: "first_name",
    label: "First Name",
    rules: {
      required: true,
      message: "Please input your first name!",
    },
  },
  {
    type: "user",
    name: "last_name",
    label: "Last Name",
    rules: {
      required: true,
      message: "Please input yout last name!",
    },
  },
  {
    type: "additional",
    name: "hear_about_us",
    label: "How did you hear about us?",
    rules: {
      required: true,
      message: "Please let us know!",
    },
  },
  {
    type: "additional",
    name: "social_media",
    label: "Which Social Media Platform will you promote on?",
    rules: {
      required: true,
      message: "Please let us know!",
    },
  },
  {
    type: "additional",
    name: "posting_where",
    label: "List your page Names and Follower count:",
    extra:
      "Minimum Requirement is 20,000+ followers or Views (Espire will NEVER ask for your passwords or emails on social media accounts) Failure to list your pages will get your application denied",
    rules: {
      required: true,
      message: "Please let us know!",
    },
  },
  {
    type: "additional",
    name: "contact_info",
    label: "How should we contact you?:",
    rules: {
      required: true,
      message: "Please let us know!",
    },
  },
  {
    type: "additional",
    name: "telegram",
    label: "Telegram username:",
    rules: {
      required: true,
      message: "Please let us know!",
    },
  },
  {
    type: "additional",
    name: "kik",
    label: "Kik username:",
    rules: {
      required: true,
      message: "Please let us know!",
    },
  },
  {
    type: "additional",
    name: "traffic_source",
    label: "How are you going to influence traffic?:",
    rules: {
      required: true,
      message: "Please let us know!",
    },
  },
  {
    type: "additional",
    name: "references",
    label: "List up to 3 references from the community?:",
    rules: {
      required: true,
      message: "Please let us know!",
    },
  },
  {
    type: "additional",
    name: "follower_count",
    label: "How Many Followers do you have?:",
    rules: {
      required: true,
      message: "Please let us know!",
    },
  },
  {
    type: "additional",
    name: "contact_email",
    label: "What is your email for contact?:",
    rules: {
      required: true,
      message: "Please let us know!",
    },
  },
  {
    type: "additional",
    name: "contact_phone",
    label: "What is your Text Message for notifications or iMessage?",
    rules: {
      required: true,
      message: "Please let us know!",
    },
  },
  {
    type: "terms",
    name: "terms",
    label: "Do you understand the following?",
    extra:
      "Espire Does not allow Porn to be used to advertise, Promoting using porn will result in an immediate ban from the offer and you will not be paid. Signing up for Espire means you acknowledge and Understand this:",
    rules: {
      required: true,
      message: "Please let us know!",
    },
  },
];
