import React, { useState, useEffect } from "react";
import moment from "moment";
import { PageHeader, List, Button, Tag } from "antd";
import { Input, Row, Col, message, Spin, Result } from "antd";
import HasOffersAPI from "../../../services/HasOffersAPI";
import { searchAffiliateUserByName, reportToSentry } from "../../../util";
import { PandaSvg } from "../../../assets/SVG/panda";
import Icon from "@ant-design/icons";
import "./AffiliatePage.scss";
const PandaIcon = (props) => <Icon component={PandaSvg} {...props} />;

const { Search } = Input;

function PendingAffiliates() {
  const Affiliate = HasOffersAPI.affiliate;
  const [initLoading, setInitLoading] = useState(true);
  const [allPending, setAllPending] = useState([]);
  const [loadingkey, setLoadingKey] = useState(null);
  const [accepting, setAccepting] = useState(null);
  const [dataEmpty, setDataEmpty] = useState(false);
  const [data, setData] = useState([
    {
      Affiliate: null,
      AffiliateUser: null,
    },
  ]);

  const fetchAffiliates = async (query) => {
    return new Promise((resolve, reject) => {
      Affiliate.findAffiliates(query, false)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  };
  const fetchPending = async () => {
    try {
      let affiliates = await fetchAffiliates({
        [`filters[status]`]: "pending",
        [`contain[]`]: "AffiliateUser",
        limit: 100,
      });
      setData(affiliates);
      setAllPending(affiliates);
      setInitLoading(false);
    } catch (error) {
      setData([
        {
          Affiliate: null,
          AffiliateUser: null,
        },
      ]);
      setInitLoading(false);
      setDataEmpty(true);
      reportToSentry([
        "api",
        `Error fetching pending affiliate list`,
        "HasOffersAPI",
      ]);
    }
  };
  useEffect(() => {
    fetchPending();
  }, []);

  const onSearch = async (value, list) => {
    setInitLoading(true);
    setData([]);
    try {
      setData(searchAffiliateUserByName(value, list));
      setInitLoading(false);
    } catch (error) {
      setData([]);
      setInitLoading(false);
    }
  };
  const onChange = async (e) => {
    let { value } = e.target;
    if (value.length < 1) {
      setInitLoading(false);
      setData(allPending);
    } else {
      onSearch(value, data);
    }
  };
  const removeFromList = (key, type) => {
    let newData = [...data];
    newData.splice(0, 1);
    setInitLoading(false);
    setData(newData);
    fetchPending();
  };
  const approve = async (id, key, type) => {
    setAccepting(true);
    setLoadingKey(key);
    message.loading({
      content: "Accepting...",
      key: "accepting",
    });
    try {
      await Affiliate.updateField(id, "status", "active");
      message
        .success({
          content: `Accepted affiliate ${id}`,
          duration: 1.5,
          key: "accepting",
        })
        .then(() => {
          setLoadingKey(null);
          setAccepting(false);
          removeFromList(key, type);
        });
    } catch (error) {
      message
        .error({
          content: `Please try again later`,
          duration: 1,
          key: "accepting",
        })
        .then(() => {
          setLoadingKey(null);
          setAccepting(false);
          reportToSentry([
            "api",
            `Error acceptinf affiliate user`,
            "HasOffersAPI",
          ]);
        });
    }
  };

  return (
    <div className="affiliate_page">
      <PageHeader
        className="site-page-header main_page_header"
        extra={
          <Search
            className="search_input"
            placeholder="Search Affiliate Name"
            onSearch={(value) => onSearch(value)}
            onChange={(value) => onChange(value)}
          />
        }
        title={`Pending Affiliates`}
      >
        <div className="demo-infinite-container">
          <List
            header={
              <Row>
                <Col xs={0} sm={0} md={3} lg={3} xl={3}>
                  ID
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8}>
                  Full Name
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8}>
                  Email
                </Col>
                <Col xs={0} sm={0} md={3} lg={3} xl={3}>
                  Joined
                </Col>
                <Col xs={0} sm={0} md={2} lg={2} xl={2} />
              </Row>
            }
            loading={initLoading}
            itemLayout="horizontal"
            dataSource={data}
            pagination={{
              pageSize: 15,
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
            renderItem={(aff, key) => {
              let item = aff.AffiliateUser;
              return (
                <>
                  {" "}
                  {dataEmpty ? (
                    <Result
                      icon={<PandaIcon style={{ fontSize: "32px" }} />}
                      subTitle="No Pending Affiliates Found"
                      // subTitle="Please select a different date"
                    />
                  ) : (
                    item && (
                      <Row>
                        <Col xs={24} sm={24} md={0} lg={0} xl={0}>
                          <List.Item
                            actions={[
                              <Button
                                onClick={() =>
                                  approve(item.affiliate_id, key, "Approved")
                                }
                                type="link"
                                key="list-loadmore-edit"
                              >
                                Approve
                              </Button>,
                            ]}
                          >
                            <List.Item.Meta
                              title={
                                <div>
                                  <Tag>#{item.affiliate_id}</Tag>
                                  <a href="https://ant.design">
                                    {`${item.first_name} ${item.last_name}`}
                                  </a>
                                </div>
                              }
                              description={item.email}
                            />
                          </List.Item>
                          <div />
                        </Col>
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}>
                          <span>#{item.affiliate_id}</span>
                        </Col>
                        <Col xs={0} sm={0} md={8} lg={8} xl={8}>
                          {`${item.first_name} ${item.last_name}`}
                        </Col>
                        <Col xs={0} sm={0} md={8} lg={8} xl={8}>
                          {item.email}
                        </Col>
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}>
                          {item.join_date &&
                            moment(item.join_date).format("M/DD/YY")}
                        </Col>
                        <Col xs={0} sm={0} md={2} lg={2} xl={2}>
                          <Button
                            // loading={loadingkey === key && true}
                            onClick={() =>
                              approve(item.affiliate_id, key, "Approved")
                            }
                            disabled={
                              accepting
                                ? loadingkey !== key
                                  ? true
                                  : false
                                : false
                            }
                            type="link"
                            style={{ width: "100%" }}
                            key="list-loadmore-edit"
                          >
                            {loadingkey === key ? (
                              <Spin size="small" />
                            ) : (
                              "Approve"
                            )}
                          </Button>
                        </Col>
                      </Row>
                    )
                  )}
                </>
              );
            }}
          />
        </div>
      </PageHeader>
    </div>
  );
}

export default PendingAffiliates;
