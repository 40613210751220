import React, { useState, useEffect } from "react";
import moment from "moment";
import HasOffersAPI from "../../services/HasOffersAPI";
import { PageHeader, Menu, List, Dropdown, Row, Button } from "antd";
import { formatCurrency } from "../../util";
import { reportToSentry } from "../../util";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { ExclamationOutlined } from "@ant-design/icons";
import Dates from "../DateRange";
const Today = moment().format("YYYY-MM-DD");
const calculate = (x) => {
  let num = x * 0.5;
  return Number.parseFloat(num).toFixed(2);
};
const StatCard = ({ title, data }) => (
  <List.Item>
    <List.Item.Meta title={title} />
    <div className="list_amount">{data && data === "null" ? <ExclamationOutlined style={{ color: "#800121" }} /> : `${formatCurrency(data)}`}</div>
  </List.Item>
);
const TopOffers = ({ auth }) => {
  const Report = HasOffersAPI.report;
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [dropdownActive, setDropdownActive] = useState(false);
  const [dropdownSelected, setDropdownSelected] = useState(Dates[0].value);

  const fetchStats = async (query) => {
    return new Promise((resolve, reject) => {
      Report.getStats(query, true)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  };
  const grabStats = async (query, dates, filter) => {
    try {
      // const test_affiliate_id = auth.profile.affiliateId;
      const test_affiliate_id = 1166;

      const api = await fetchStats({
        [`fields`]: ["Stat.payout", "Offer.name"],
        [`filters[Stat.affiliate_id][conditional]`]: "EQUAL_TO",
        [`filters[Stat.affiliate_id][values]`]: test_affiliate_id,
        [`filters[Stat.source][conditional]`]: "EQUAL_TO",
        [`filters[Stat.source][values]`]: "horo",
        [`filters[Stat.date][conditional]`]: "BETWEEN",
        [`sort[Stat.payout]`]: "desc",
        totals: 1,
        limit: 10,
        ...query,
      });
      let data = api.data;
      const new_data = [];
      data.map((item) =>
        new_data.push({
          Stat: {
            payout: calculate(item.Stat.payout),
          },
          Offer: item.Offer,
        })
      );
      setOffers(new_data);
      setLoading(false);
    } catch (error) {
      reportToSentry(["performance_chart", `Error fetching account totals data`, "HasOffersAPI"]);
      return "error";
    }
  };
  useEffect(() => {
    const stats = async () => {
      await grabStats({
        [`filters[Stat.date][values]`]: [Today, Today],
      });
    };
    stats();
  }, []);
  const onClick = async ({ item }) => {
    setDropdownSelected(item.props.dates.value);
    setLoading(true);
    const { start_date, end_date } = item.props.dates.current;
    await grabStats({
      [`filters[Stat.date][values]`]: [start_date, end_date],
    });
  };
  const menu = (
    <Menu onClick={onClick}>
      {Dates.map((date) => {
        return (
          <Menu.Item dates={date} key={date.value}>
            {date.value}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return (
    <PageHeader
      className="main_page_header"
      style={{ width: "100%" }}
      title={`Top Offers`}
      extra={[
        <Dropdown key="1" placement="bottomRight" onVisibleChange={(visible) => setDropdownActive(visible)} overlay={menu} trigger={["click"]}>
          <Button>
            {dropdownSelected}
            {dropdownActive ? <CaretUpOutlined /> : <CaretDownOutlined />}
          </Button>
        </Dropdown>,
      ]}>
      <Row>
        <List loading={loading} className="account_total_list" size="small" style={{ width: "100%" }} bordered={false} dataSource={offers} renderItem={(item) => <StatCard title={item.Offer.name} data={item.Stat.payout} />} />
      </Row>
    </PageHeader>
  );
};
export default TopOffers;
