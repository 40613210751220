import React, { useState } from "react";
import { Button, Menu, Dropdown } from "antd";
import Dates from "../DateRange";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { reportToSentry } from "../../util";

const DropDownDates = ({
  compared_stats,
  setDropdownSelected,
  dropdownSelected,
  isCustom,
}) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  //   const [dropdownSelected, setSelected] = useState(Dates[0].value);
  let New_Dates = isCustom
    ? Dates.concat({
        value: "Custom",
        current: { start_date: "", end_date: "" },
      })
    : Dates;
  const onClick = async ({ item }) => {
    const { dates } = item.props;
    let filter =
      dates.value === "Today" || dates.value === "Yesterday"
        ? "Stat.hour"
        : "Stat.date";
    try {
      setDropdownSelected(dates.value);
      await compared_stats(dates.current, dates.previous, filter);
    } catch (erro) {
      reportToSentry([
        "performance_chart",
        `Error fetching  initial line chart data`,
        "HasOffersAPI",
      ]);
    }
  };
  return (
    <Dropdown
      key="1"
      placement="bottomLeft"
      onVisibleChange={(visible) => setDropdownActive(visible)}
      overlay={
        <Menu onClick={onClick}>
          {New_Dates.map((date) => {
            return (
              <Menu.Item dates={date} key={date.value}>
                {date.value}
              </Menu.Item>
            );
          })}
        </Menu>
      }
      trigger={["click"]}
    >
      <Button>
        {dropdownSelected}
        {dropdownActive ? <CaretUpOutlined /> : <CaretDownOutlined />}
      </Button>
    </Dropdown>
  );
};

export default DropDownDates;
