import { useState, useEffect } from "react";
import HasOffersAPI from "./HasOffersAPI";
import Backend from "./Backend";

const Report = HasOffersAPI.report;
const Affiliate = HasOffersAPI.affiliate;

export function FetchReport(customQuery) {
  console.log("customQuery", customQuery);

  const [query] = useState(customQuery);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchData = async (query) => {
    try {
      setData(await Report.getReportStats(query));
    } catch (e) {
      console.log("error", e);

      setError(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData(query);
  }, [query]);
  return [data, isLoading, error, fetchData];
}
export function FetchStats(customQuery) {
  const [query] = useState(customQuery);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchData = async (query) => {
    try {
      setData(await Report.getStats(query, true));
    } catch (e) {
      console.log("error", e);
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData(query);
  }, [query]);
  return [data, isLoading, error, fetchData];
}
export function RefreshProfile(hasoffers_profile) {
  const [profile] = useState(hasoffers_profile);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchData = async (profile) => {
    try {
      setData(await Backend.fetchProfile(profile));
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData(profile);
  }, [profile]);
  return [data, isLoading, error, fetchData];
}
export function FetchAffiliates(customQuery) {
  const [query] = useState(customQuery);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchData = async (query) => {
    try {
      setData(await Affiliate.findAffiliates(query, true));
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData(query);
  }, [query]);
  return [data, isLoading, error, fetchData];
}

export function SignUpQuestions() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchData = async () => {
    try {
      setData(await Affiliate.getSignupQuestions());
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return [data, isLoading, error, fetchData];
}

export function FetchOffer(customQuery) {
  const [query] = useState(customQuery);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchData = async (query) => {
    try {
      setData(await Report.getOffer(query, true));
    } catch (e) {
      console.log("error", e);
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData(query);
  }, [query]);
  return [data, isLoading, error, fetchData];
}
export function FetchOffers(customQuery) {
  const [query] = useState(customQuery);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchData = async (query) => {
    try {
      const all_offers = await Report.getStats(query, true);
      const offers = [];
      all_offers.data.map(async (offer) => {
        const { offer_id } = offer.Stat;
        const offer_info = await Report.getOffer(
          {
            id: offer_id,
            [`fields`]: ["preview_url", "name", "description", "id"],
            [`contain`]: ["Thumbnail", "Stat", "OfferCategory"],
          },
          true
        );
        if (offer_info.OfferCategory[37]) {
          offers.push({ ...offer_info });
        }
      });
      setData(offers);
      return offers;
    } catch (e) {
      setError(e);
      return e;
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData(query);
  }, [query]);
  return [data, isLoading, error, fetchData];
}
