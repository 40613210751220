import {
  SET_AUTH,
  SET_AUTH_HASOFFERS,
  LOG_OUT,
  RESET_AUTH_HASOFFERS,
} from "../actions";
const authState = {
  isLoggedin: false,
};
const hasoffersProfile = {};

export const auth = (state = authState, action) => {
  if (action.type === SET_AUTH) {
    // console.log("SET_AUTH", action);
    state = {
      ...state,
      ...action.payload,
      isLoggedin: action.payload.success,
    };
  } else if (action.type === LOG_OUT) {
    // console.log("LOG_OUT", action);
    state = {
      ...authState,
    };
  }
  return state;
};
export const hasoffers_profile = (state = hasoffersProfile, action) => {
  if (action.type === SET_AUTH_HASOFFERS) {
    state = {
      ...state,
      ...action.payload,
    };
  } else if (action.type === RESET_AUTH_HASOFFERS) {
    // console.log("RESET", action);
    state = {
      ...hasoffersProfile,
    };
  }
  return state;
};
