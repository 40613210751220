import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { auth, hasoffers_profile } from "./AuthReducer";

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: [""],
};

const reducers = combineReducers({
  auth,
  hasoffers_profile,
});

export default persistReducer(rootPersistConfig, reducers);
