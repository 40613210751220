import React, { useState } from "react";
import Dates from "../../../components/DateRange";
import { reportToSentry } from "../../../util";
import DropDownDates from "../../../components/Widgets/DropDownDates";
import { Collapse, Button, Divider, Checkbox, DatePicker } from "antd";
import { FetchReport } from "../../../services/ApiHooks";
import ReportTable from "./ReportTable";
import ReportsChart from "./ReportsChart";
import "./reports.scss";
const { RangePicker } = DatePicker;

const { Panel } = Collapse;

const ReportPage = () => {
  let query = {
    date: Dates[0].current,
    sort: "Stat.payout",
    fields: [
      "Affiliate.company",
      "Stat.conversions",
      "Stat.clicks",
      "Stat.payout",
      "Stat.date",
    ],
  };
  const [data, isLoading, error, fetchData] = FetchReport(query, true);
  const [dropdownSelected, setDropdownSelected] = useState(Dates[0].value);
  const [options, setOptions] = useState(query);

  const [stats_fields] = useState([
    { label: "CTR", value: "Stat.ctr" },
    { label: "RPA", value: "Stat.rpa" },
    { label: "CPC", value: "Stat.cpc" },
    { label: "CPA", value: "Stat.cpa" },
    // { label: "RPM", value: "Stat.rpm" },
    // { label: "Partner ID", value: "Stat.affiliate_id" },
  ]);
  const onClick = (date) => setOptions({ ...options, date });
  const onChangeStatFields = (checkedValues) => {
    let fields = query.fields.concat(checkedValues);
    setOptions({
      ...options,
      fields: fields,
      sort: checkedValues.includes("Stat.hour") ? "Stat.hour" : query.sort,
    });
  };

  const onChangeDateRange = (moment, date) => {
    setOptions({
      ...options,
      date: {
        start_date: date[0],
        end_date: date[1],
      },
    });
  };

  const runReport = async () => {
    try {
      await fetchData({ ...options });
    } catch (error) {
      reportToSentry([
        "performance_chart",
        `Error fetching  initial line chart data`,
        "HasOffersAPI",
      ]);
    }
  };
  return (
    <div id="report_page">
      <DropDownDates
        key="1"
        dropdownSelected={dropdownSelected}
        setDropdownSelected={setDropdownSelected}
        compared_stats={onClick}
        isCustom={true}
      />
      {dropdownSelected === "Custom" && (
        <RangePicker
          style={{ marginLeft: 10 }}
          onChange={onChangeDateRange}
          format="YYYY-MM-DD"
        />
      )}
      <Collapse accordion>
        <Panel header="Options" key="1">
          <Divider orientation="left">Stats</Divider>
          <Checkbox.Group
            options={stats_fields}
            onChange={onChangeStatFields}
            defaultValue={query.fields}
          />
        </Panel>
      </Collapse>
      <Button type="primary" className="report-btn" onClick={runReport}>
        Run Report
      </Button>
      <ReportsChart
        date={dropdownSelected}
        error={error}
        isLoading={isLoading}
        data={data}
      />

      {/* {byHours && (
        <Collapse accordion>
          <Panel header="Trends" key="2">
            <ReportsChart error={error} isLoading={isLoading} data={data} />
          </Panel>
        </Collapse>
      )} */}

      <ReportTable error={error} isLoading={isLoading} data={data} />
    </div>
  );
};

export default ReportPage;
