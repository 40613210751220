import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import * as Firestore from "../services/Firebase";
import { authAction } from "../state/actions/AuthAction";
import AuthContainer from "./Auth/AuthContainer";
import DashboardContainer from "./Dashboard/DashboardContainer";
import RegisterPage from "./Auth/RegisterPage";
import LoginPage from "./Auth/LoginPage";
import HomePage from "./Dashboard/HomePage/HomePage";
import ProfilePage from "./Dashboard/ProfilePage/ProfilePage";
import ManageAffiliates from "./Dashboard/AffiliatesPage/ManageAffiliates";
import PendingAffiliates from "./Dashboard/AffiliatesPage/PendingAffiliates";
import ReportPage from "./Dashboard/Report/ReportPage";
import OffersPage from "./Dashboard/OffersPage/OffersPage";
import * as Sentry from "@sentry/browser";

// import ErrorBoundary from "./Sentry";

const Notfound = () => <h1>Not found</h1>;

// const { useBreakpoint } = Grid;

function App({ authAction, auth, hasoffers_profile }) {
  const [, setLogin] = useState(false);
  const [profile] = useState(false);
  const onAuthStateChange = () => {
    if (auth.isLoggedin) {
      Sentry.configureScope(function (scope) {
        scope.setUser({ email: auth.profile.email, id: auth.profile.id });
      });
    }
    Firestore.onAuthStateChange(setLogin, authAction, hasoffers_profile, auth.isLoggedin);
  };
  useEffect(() => {
    onAuthStateChange();
  }, [profile]);

  if (auth.isLoggedin) {
    return (
      <Router>
        <Switch>
          <DashboardContainer>
            <Route exact path="/" component={HomePage} />
            <Route path="/profile" component={ProfilePage} />
            <Route path="/affiliates/manage" render={(props) => <ManageAffiliates props={props} auth={auth} />} />
            <Route path="/affiliates/pending" render={(props) => <PendingAffiliates props={props} auth={auth} />} />
            <Route path="/reports" render={(props) => <ReportPage props={props} auth={auth} />} />
            <Route path="/offers" render={(props) => <OffersPage props={props} auth={auth} />} />
          </DashboardContainer>
          <Route component={Notfound} />
        </Switch>
      </Router>
    );
  }

  return (
    <Router>
      <Switch>
        <AuthContainer>
          <Route exact path="/" component={LoginPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={RegisterPage} />
          <Redirect to="/" />
        </AuthContainer>
      </Switch>
    </Router>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  hasoffers_profile: state.hasoffers_profile,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ authAction }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(App);
