import React from "react";

import { Button } from "antd";
import { Form, Input, Modal } from "antd";

function EditFieldModal({
  visible,
  modalData,
  setModalVisible,
  onFinish,
  confirmLoading,
}) {
  return (
    <Modal
      closable={false}
      title={`Edit ${modalData.title}`}
      confirmLoading={confirmLoading}
      visible={visible}
      footer={[
        <Button key="back" onClick={() => setModalVisible(false)}>
          Cancel
        </Button>,
        <Button
          form="submitForm"
          key="submit"
          type="primary"
          htmlType="submit"
          loading={confirmLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form id="submitForm" onFinish={onFinish}>
        <Form.Item initialValue={modalData.value} name={modalData.key}>
          <Input name={`${modalData.key}`} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditFieldModal;
