import * as firebase from "firebase/app";
import * as Sentry from "@sentry/browser";

import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
};

firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();

export const signInWithEmailAndPassword = (form, setError, setLoading) => {
  let { email, password } = form;
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(() => setLoading(false))
    .catch((error) => {
      setLoading(false);
      setError(error);
    });
};

export const signInWithCustomToken = (token, setError, setLoading) => {
  return firebase
    .auth()
    .signInWithCustomToken(token)
    .catch((error) => Sentry.captureException(error));
};

export const onAuthStateChange = (setLogin, authAction) => {
  return firebase.auth().onAuthStateChanged(async (user) => {
    if (!user) {
      authAction({}, "LOG_OUT");
      authAction({}, "RESET_AUTH_HASOFFERS");
      setLogin(false);
      Sentry.configureScope(function (scope) {
        scope.setUser({ email: null, id: null });
      });
    } else {
      // authAction({ isLoggedin: true }, "SET_AUTH");
      setLogin(true);
    }
  });
};
export const onLogout = (authAction) => {
  authAction({}, "LOG_OUT");
  authAction({}, "RESET_AUTH_HASOFFERS");
  firebase.auth().signOut();
};

export const getOfferCreatives = (id) => {
  console.log("offer", id);
  var docRef = db.collection("offers.cards").doc(`offer-${id}`);
  return docRef.get();
};

// pick up hours 10
// tax 8.875

// sinfronterasrestaurant.com
