import React, { useState, useEffect } from "react";
import { PageHeader } from "antd";
import { reportToSentry } from "../../util";
import HasOffersAPI from "../../services/HasOffersAPI";

import Dates from "../DateRange";
import { PandaSvg } from "../../assets/SVG/panda";
import Icon from "@ant-design/icons";
import { date_label, hour_am_pm, chart_data } from "../../helpers/Charts";
import { dateRangeLabel } from "../../helpers/Charts";
import DropDownDates from "./DropDownDates";
import PerformanceChart from "./PerformanceChart";

const currentChartColor = "rgba(43,113,174,0.4)";
const previousChartColor = "rgb(255, 170, 69)";

const PandaIcon = (props) => <Icon component={PandaSvg} {...props} />;
const Performance = ({ auth }) => {
  const Report = HasOffersAPI.report;
  const [initialLoading, setInitialLoading] = useState(true);
  const [dropdownSelected, setDropdownSelected] = useState(Dates[0].value);

  const [labels, setLabels] = useState([]);

  const [currentDate, setCurrentDate] = useState(Dates[0].current);
  const [currentData, setCurrentData] = useState([]);
  const [earningTotals, setEarningTotals] = useState(null);

  const [prevDate, setPrevDate] = useState(Dates[0].previous);
  const [prevData, setPrevData] = useState([]);
  const [, setPrevTotals] = useState(null);
  const calculate = (x) => {
    let num = x * 0.5;
    return Number.parseFloat(num).toFixed(2);
  };
  const fetchStats = async (query) => {
    return new Promise((resolve, reject) => {
      Report.getStats(query)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  };
  const grabStats = async (dates, filter) => {
    let { start_date, end_date } = dates;
    try {
      // const test_affiliate_id = auth.profile.affiliateId;
      const test_affiliate_id = 1166;
      const api = await fetchStats({
        [`fields`]: ["Stat.payout", "Stat.conversions", "Stat.clicks", filter],
        [`filters[Stat.affiliate_id][conditional]`]: "EQUAL_TO",
        [`filters[Stat.affiliate_id][values]`]: test_affiliate_id,
        [`filters[Stat.source][conditional]`]: "EQUAL_TO",
        [`filters[Stat.source][values]`]: "horo",
        [`filters[Stat.date][conditional]`]: "BETWEEN",
        [`sort[${filter}]`]: "asc",
        totals: 1,
        [`filters[Stat.date][values]`]: [start_date, end_date],
      });
      let data = api.data;
      if (data.length === 0) {
        setCurrentData(null);
        setInitialLoading(false);
      } else {
        let byHours = data[0].Stat.hour ? true : false;
        const merged_data = [];
        const new_data = [];
        data.map((key) => {
          const { clicks, conversions, payout, hour, date } = key.Stat;
          // console.log("jey", key.Stat);
          const new_merged = {
            clicks: calculate(clicks),
            conversions: calculate(conversions),
            payout: calculate(payout),
            [`${byHours ? "hour" : "date"}`]: byHours ? hour : date,
          };
          new_data.push({ Stat: new_merged });
          return merged_data.push(new_merged);
        });

        const labels = byHours ? hour_am_pm(merged_data) : date_label(merged_data);
        const newData = chart_data(new_data);
        const new_totals = {
          clicks: Math.round(calculate(api.totals.Stat.clicks)),
          conversions: Math.round(calculate(api.totals.Stat.conversions)),
          payout: Math.round(calculate(api.totals.Stat.payout)),
        };
        return {
          labels,
          data: newData,
          total: new_totals,
        };
      }
    } catch (error) {
      reportToSentry(["performance_chart", `Error fetching line chart data`, "HasOffersAPI"]);
    }
  };
  const compared_stats = async (currentDate, previousDate, filter) => {
    try {
      let current = await grabStats(currentDate, filter);
      setCurrentDate(currentDate);
      setCurrentData(current.data);
      setEarningTotals(current.total);
      let previous = await grabStats(previousDate, filter);
      setPrevDate(previousDate);
      setPrevData(previous.data);
      setPrevTotals(previous.total);
      setLabels(previous.labels.length > current.labels.length ? previous.labels : current.labels);
    } catch (error) {
      reportToSentry(["performance_chart", `Error fetching  selected line chart data`, "HasOffersAPI"]);
    }
  };

  useEffect(() => {
    const fetchStats = async () => {
      await compared_stats(Dates[0].current, Dates[0].previous, "Stat.hour");
      setInitialLoading(false);
    };
    fetchStats();
  }, [setInitialLoading]);

  const defaultData = {
    lineTension: 0.1,
    borderCapStyle: "butt",
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderColor: "#00000000",
    pointBackgroundColor: "#00000000",
    pointBorderWidth: 0,
    pointHoverRadius: 3,
    pointHoverBorderWidth: 0,
    pointRadius: 3,
    pointHitRadius: 10,
    borderWidth: 0,
  };
  const chartData = {
    labels: labels,
    datasets: [
      {
        actual_month: currentDate.start_date,
        dropdownSelected,
        label: dateRangeLabel(currentDate),
        data: currentData,
        fill: true,
        order: 1,
        backgroundColor: currentChartColor,
        borderColor: currentChartColor,
        pointHoverBackgroundColor: currentChartColor,
        pointHoverBorderColor: currentChartColor,
        borderDash: [],
        ...defaultData,
      },
      {
        actual_month: prevDate.start_date,
        dropdownSelected,
        label: dateRangeLabel(prevDate),
        data: prevData,
        fill: false,
        order: 2,
        backgroundColor: previousChartColor,
        borderColor: previousChartColor,
        pointHoverBackgroundColor: previousChartColor,
        pointHoverBorderColor: previousChartColor,
        borderDash: [10, 5],
        ...defaultData,
      },
    ],
  };
  const byHours = labels.length > 0 && labels[0].includes("M");
  const onChangeTabs = (currentTab) => {
    if (currentTab == 2) {
      //grab traffic data
      console.log("change tabs", currentTab);
    }
  };
  return (
    <PageHeader className="main_page_header" title={`Performance`} extra={[<DropDownDates key="1" setDropdownSelected={setDropdownSelected} setInitialLoading={setInitialLoading} compared_stats={compared_stats} />]}>
      <div className="chart-container">
        <PerformanceChart PandaIcon={PandaIcon} initialLoading={initialLoading} currentData={currentData} chartData={chartData} currentTotals={earningTotals} byHours={byHours} />
      </div>
    </PageHeader>
  );
};
export default Performance;
