import { config } from "../config";
import { toCamel, cleanIncludesAffiliateUser } from "../util";
import * as Sentry from "@sentry/browser";
const objectMerge = require("object-merge");

export class AccountTypes {
  static Affiliate = "affiliate_user";
  static Advertiser = "advertiser_user";
  static Employee = "employee";

  // static loginButtons = ['Influencer', 'Advertiser', 'Employee'];
  static loginButtons = ["Influencer", "Employee"];
  static getTypeFromIndex = (index) => {
    switch (index) {
      case 0:
        return AccountTypes.Affiliate;
      case 1:
        return AccountTypes.Advertiser;
      case 2:
        return AccountTypes.Employee;
      default:
    }
  };
}

/**
 * HasOffers API constructor
 *
 * Based off code from https://github.com/01000101/hasoffers-network-api
 *
 * @constructor
 * @param {String} networkToken - API network token.
 * @param {String} networkId - API network IDen.
 * @param {String} networkId - API network ID
 * @param {Object} options - Various options to configure how to
 *  interact with the API. Currently configurable keys are:
 *    "apiEndpoint" - API HTTP endpoint to use.
 *    "paging.limit" - Maximum number of results per returned page.
 *    "paging.delay" - Delay (in ms) between page requests.
 */

class HasOffersAPI {
  constructor(options = {}) {
    this.apiEndpoint = options.apiEndpoint || config.hasoffers.url;
    this.paging = {
      limit: options.pagingLimit || 1000,
      delay: options.pagingDelay || 750,
    };
    this.employee = this._baseController("Employee");
    this.offer = this._baseController("Offer");
    this.advertiserUser = this._baseController("AdvertiserUser");
    this.advertiser = this._baseController("Advertiser");
    this.affiliateUser = this._baseController("AffiliateUser");

    this.affiliate = {
      ...this._baseController("Affiliate"),
      getAccountManager: (affiliateId) => this.request("Affiliate", "getAccountManager", { id: affiliateId }),
      getPaymentMethods: (affiliateId) => this.request("Affiliate", "getPaymentMethods", { id: affiliateId }),
      updatePaymentMethod: (tag, data) => {
        const controller = `updatePaymentMethod${tag.replace(/_/g, "")}`;
        return this.request("Affiliate", controller, {
          affiliate_id: data.affiliate_id,
          data,
        });
      },
    };
    this.report = this._baseController("Report");
  }

  authentication = {
    findUserByCredentials: async (email, password, type) => {
      return toCamel(
        await this.request("Authentication", "findUserByCredentials", {
          email,
          password,
          type,
        })
      );
    },
  };

  /**
   * Log in and fetch account details for an affiliate or employee.
   */
  loginUser = async ({ email, password, type }) => {
    try {
      let data = await this.authentication.findUserByCredentials(email, password, type);
      if (!data) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error("Unable to perform initial authorization step!");
      }

      const deleteCredentials = async (errorMsg) => {
        throw errorMsg;
      };

      // Confirm account and user are both active
      if (!data.hasOwnProperty("userStatus") || data.userStatus !== "active") await deleteCredentials("Your user is not active! Please contact your account owner.");

      if (!data.hasOwnProperty("accountStatus") || data.accountStatus !== "active") await deleteCredentials("Your account is not active! Please contact your account manager.");

      // Update credentials in secure storage

      if (data.userType === AccountTypes.Employee) {
        return this._merge(data, await this.employee.findById(data.userId));
      } else if (data.userType === AccountTypes.Affiliate) {
        let affiliateUser = await this.affiliateUser.findById(data.userId);

        let affiliateManager = await this.affiliate.getAccountManager(affiliateUser.affiliateId);

        let new_data = this._merge(data, affiliateUser, {
          affiliateManager: affiliateManager.Employee,
        });
        return new_data;
      } else if (data.userType === AccountTypes.Advertiser) {
        return this._merge(data, await this.advertiserUser.findById(data.userId));
      }
    } catch (error) {
      Sentry.captureException(error);
      if (error === "Invalid Authorization") {
        throw new Error("Invalid email or password!");
      }
      throw error;
    }
  };

  /**
   * HasOffers controller interface for common endpoints
   * @private
   */
  _baseController = (target) => {
    return {
      findById: async (affiliateId) => {
        return this._parseSingleResult(
          await this.request(target, "findById", {
            id: affiliateId,
            // "contain[]": "AffiliateUser",
          }),
          target
        );
      },
      findAll: async (query) => {
        return this._stripIdAndType(await this.paged_request(target, "findAll", query), target);
      },
      findAllByIds: async (ids, query) => {
        query = this._merge(query || {}, { ids: ids });
        return this._stripIdAndType(await this.paged_request(target, "findAllByIds", query), target);
      },
      findAllById: async (id, query) => {
        query = this._merge(query || {}, { id: id });
        return this._stripIdAndType(await this.paged_request(target, "findAllById", query), target);
      },

      findAffiliateAffiliateUser: async (query) => {
        query = this._merge(query || {}, query);
        return cleanIncludesAffiliateUser([await this.request(target, "findById", query)]);
      },
      findAllIdsByAccountManagerId: async (query, totalOnly) => {
        query = this._merge(query || {}, query);
        let data = await this.request(target, "findAll", query);
        return totalOnly ? data : cleanIncludesAffiliateUser(data.data);
      },
      findPendingAffiliates: async (query, totalOnly) => {
        query = this._merge(query || {}, query);
        let data = await this.request(target, "findAll", query);
        return totalOnly ? data : cleanIncludesAffiliateUser(data.data);
      },
      findAffiliates: async (query, totalOnly) => {
        query = this._merge(query || {}, query);
        let data = await this.request(target, "findAll", query);
        return totalOnly ? data : cleanIncludesAffiliateUser(data.data);
      },
      /**
       * HasOffers stats controller interface for registration
       ***/
      getSignupQuestions: async () => {
        return await this.request(target, "getSignupQuestions", {
          status: "active",
        });
      },
      updateSignupQuestions: async (query) => {
        return await this.request(target, "updateSignupQuestionAnswer", query);
      },
      signUp: async (custom_query) => {
        let query = {
          account: {
            account_manager_id: 72,
            ...custom_query.account,
          },
          user: {
            ...custom_query.user,
          },
          return_object: 1,
        };
        return await this.request(target, "signup", query);
      },
      /**
       * HasOffers stats controller interface for common endpoints
       ***/
      getStats: async (query) => {
        // query = this._merge(query || {}, query);
        return await this.request(target, "getStats", query);
      },
      getReportStats: async (custom_query, includeAffID) => {
        let fields = includeAffID ? ["Stat.affiliate_id"] : [];
        custom_query.fields.map((x) => fields.push(x));
        let query = {
          [`fields`]: fields,
          [`filters[Stat.affiliate_manager_id][conditional]`]: "EQUAL_TO",
          [`filters[Stat.affiliate_manager_id][values]`]: 72,
          [`filters[Stat.clicks][conditional]`]: "GREATER_THAN",
          [`filters[Stat.clicks][values]`]: 0,
          [`filters[Stat.date][conditional]`]: "BETWEEN",
          [`sort[${custom_query.sort}]`]: "desc",
          totals: 1,
          page: 1,
          limit: 500,
          [`filters[Stat.date][values][]`]: [custom_query.date.start_date, custom_query.date.end_date],
        };
        return await this.request(target, "getStats", query);
      },
      /**
       * Get Offer information
       ***/
      getOffer: async (query) => {
        // query = this._merge(query || {}, query);
        return await this.request("Offer", "findById", query);
      },
      geneOfferLink: async (query) => {
        // query = this._merge(query || {}, query);
        return await this.request("Offer", "generateTrackingLink", query);
      },
      /**
       *
       ***/

      /**
       * HasOffers edit affiliate accounts
       ***/
      updateField: async (affiliateId, field, value) => {
        return this._parseSingleResult(
          await this.request(target, "updateField", {
            id: affiliateId,
            field: field,
            value: value,
          })
        );
      },
      /**
       *
       ***/
    };
  };

  /**
   * Makes an API request.
   *
   * @access public
   * @param {String} target - Has Offers API call target (controller).
   * @param {String} method - Has Offers API call method (routine).
   * @param {Object} query - API query parameters.
   */
  request = async (target, method, query) => {
    // Inject API credentials
    query.NetworkId = config.hasoffers.networkId;
    query.NetworkToken = config.hasoffers.networkToken;

    // Set up target
    query.Target = target;
    query.Method = method;
    query.Version = 2;
    // console.log("target ", target);
    // console.log("method ", method);
    // console.log("query ", query);
    // Make an API request
    let response = await (
      await fetch(`${this.apiEndpoint}?${this._serialize(query)}`, {
        headers: { Accept: "application/json" },
      })
    ).json();
    // console.log("response", response);
    if (response && response.hasOwnProperty("response") && !response.response.errorMessage && response.response.hasOwnProperty("data")) {
      return response.response.data;
    }

    if (response.response.errorMessage.indexOf("API usage exceeded rate limit") !== -1) {
      // Rate limit hit so wait a moment before trying again
      Sentry.captureException(new Error("Rate limit exceeded! Retrying..."));
      await new Promise((resolve) => setTimeout(resolve, this.paging.delay));
      return await this.request(target, method, query);
    } else throw response.response.errorMessage;
  };

  /**
   * Makes an API request and handles results paging. Due to the way
   * Has Offers does API request limiting, it's advisable to use
   * an API delay here between page fetches (or set the limit veryhigh).
   *
   * @access public
   * @param {String} target - Has Offers API call target (controller).
   * @param {String} method - Has Offers API call method (routine).
   * @param {Object} query - API query parameters.
   */
  paged_request = async (target, method, query) => {
    let paged_data = null,
      paged_error = null;
    // Set the total return limit for all paged requests
    query.limit = this.paging.limit;
    // Begin iterating through pages

    await this._async_loop(100, async (loop) => {
      // Pages start at 1
      query.page = loop.iteration() + 1;
      try {
        // Make an API request

        let data = await this.request(target, method, query);
        // Merge current page of data with already fetched data
        if (data.data instanceof Array) {
          paged_data = paged_data || [];
          Array.prototype.push.apply(paged_data, data.data);
        } else if (data.data instanceof Object) {
          paged_data = this._merge(paged_data || {}, data.data);
        } else if (data) {
          // findAllByIds returns data not data.data
          if (!data.data && data) {
            paged_data = this._merge(paged_data || {}, data);
          }
        } else {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error("Unexpected response data type");
        }

        // Stop the loop if we're out of pages
        if (!data.pageCount || parseInt(data.pageCount) <= loop.iteration() + 1) return loop.break();
      } catch (error) {
        paged_error = error;
        return loop.break();
      }
    });

    if (paged_error != null) {
      throw paged_error;
    }

    return paged_data;
  };

  /**
   * Helper method to synchronize asynchronous looping.
   *
   * @access private
   * @param {Integer} iterations - Number of iterations to perform.
   * @param {Function} func - Method to call every iteration. Passes in loop.
   */
  _async_loop = async (iterations, func) => {
    let index = 0;
    let done = false;
    const loop = {
      // Get the current iteration index
      iteration: () => {
        return index - 1;
      },
      // Exit the loop
      break: () => {
        done = true;
      },
    };

    do {
      // If below max iterations, execute function again
      if (index < iterations) {
        index++;
        await func(loop);
      } else done = true;

      // Sleep for paging delay if we aren't finished
      if (!done) await new Promise((resolve) => setTimeout(resolve, this.paging.delay));
    } while (!done);
  };

  /**
   * Serializes an Object to be used with the API.
   *
   * @access private
   * @param {Object} obj - Object to serialize.
   * @param {String} prefix - Parent key name (mostly used recursively).
   * @returns {String} A serialized string.
   */
  _serialize = (obj, prefix) => {
    let str = [],
      p;
    for (p in obj)
      if (obj.hasOwnProperty(p)) {
        let k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push(v !== null && typeof v === "object" ? this._serialize(v, k) : encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    return str.join("&");
  };

  /**
   * Merges two objects recursively.
   *
   * @access private
   * @param {Object} obj_dst - Object to merge to.
   * @param {Object} obj_src - Object to merge from.
   * @returns {Object} A merged object.
   */
  _merge = objectMerge;

  /**
   * The API often returns data with top level keys
   * for each item being the ID of the item and the name
   * of the type of model the item is. For instance,
   * an item could look like {"150": {"Offer": {...}}} when
   * it's already obvious it's an Offer and the ID is part of
   * the actual data (unless the user did not request the ID, in
   * which case they don't deserve to have it).
   * This strips that out and only returns the item data.
   *
   * @access private
   * @param {Array} items - Raw API results / models.
   * @param {String} key - Model name to remove.
   * @returns {Array} An array of normalized API results.
   */
  _stripIdAndType = (items, key) => {
    let data = [];
    for (let item in items || []) if (items.hasOwnProperty(item) && items[item].hasOwnProperty(key)) data.push(items[item][key]);
    return data;
  };

  /**
   * Handle nested data returned from HasOffers API for single-result requests
   * @param data Returned API data
   * @param target API target
   * @returns {*}
   * @private
   */
  _parseSingleResult = (data, target) => {
    if (!data.hasOwnProperty(target)) {
      return toCamel(data);
    }
    return toCamel(data[target]);
  };
}

export default new HasOffersAPI();
