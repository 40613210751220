import React, { useState } from "react";
import { Select, Spin } from "antd";
import { Bar } from "react-chartjs-2";
import { capitalizeFirstLetter, convertColumnType } from "../../../util";
import { chart_colors } from "../../../helpers/Charts";

const { Option } = Select;

const defaultData = {
  lineTension: 0.1,
  borderCapStyle: "butt",
  borderDashOffset: 0.0,
  borderJoinStyle: "miter",
  pointBorderColor: "#00000000",
  pointBackgroundColor: "#00000000",
  pointBorderWidth: 0,
  pointHoverRadius: 3,
  pointHoverBorderWidth: 0,
  pointRadius: 3,
  pointHitRadius: 10,
  borderWidth: 2,
  fill: false,
};
const ReportsCharts = ({ data, isLoading, date }) => {
  const [sortType, setSortType] = useState("payout");
  const [showTop, setShowTop] = useState(5);
  const merged_data = [];
  isLoading ||
    data.data.map((key) => merged_data.push({ ...key.Affiliate, ...key.Stat }));
  // const byHours = merged_data[0].hour ? true : false;
  const users = [];

  // grab users company info only
  // console.log("data in report chart", merged_data);

  merged_data.map((key) => users.push(key.company));
  // clean up user object ans remove duplicates
  const unique_users = [...new Set(users)];

  // sort merged data by the hour

  const last_sorted_data = [];

  // map through unique users and pass array with totals and data and set empty data to 0

  unique_users.map((user) => {
    const chart_data = [];
    merged_data.map((key) => {
      // console.log("key", key);
      return (
        user === key.company &&
        chart_data.splice(parseInt(key.date), 1, Number(key[sortType]))
      );
    });
    last_sorted_data.push({
      company: user,
      data: chart_data,
      total: chart_data.reduce((a, b) => +a + +b, 0),
    });
  });

  // create datasets for each affiliate and sort by highest total
  last_sorted_data.sort((a, b) => b.total - a.total);

  const top_5 = last_sorted_data.slice(0, showTop);
  const datasets = [];
  top_5.map((key, val) => {
    console.log("key", key);
    return datasets.push({
      spanGaps: true,
      data: key.data,
      label: key.company,
      ...defaultData,
      backgroundColor: chart_colors[val],
      borderColor: chart_colors[val],
      pointHoverBackgroundColor: chart_colors[val],
      pointHoverBorderColor: chart_colors[val],
      borderDash: [],
    });
  });

  const hours = Array.from({ length: 24 }, (v, k) => ({ hour: k }));
  const hour_labels = ["Today"];
  console.log("hour_labels", hour_labels);
  const filters = [];
  isLoading ||
    Object.keys(merged_data[0]).map(async (keys) => {
      keys === "company" ||
        keys === "affiliate_id" ||
        keys === "hour" ||
        filters.push(keys);
    });

  const onSelectOptions = (value) => {
    setSortType(value);
  };
  const onSelectTop = (value) => {
    setShowTop(value);
  };

  const sortOptions = filters.map((option, key) => (
    <Option key={key} value={option}>
      {option.length <= 4
        ? option.toUpperCase()
        : capitalizeFirstLetter(option)}
    </Option>
  ));

  const chartData = {
    labels: hour_labels,
    type: "line",
    datasets: datasets,
  };
  // console.log("chartData", chartData);
  let type = convertColumnType(sortType);
  return (
    <div>
      <Select
        className="sort_options"
        defaultValue={sortType}
        onSelect={onSelectOptions}
        style={{ width: 200 }}
      >
        {sortOptions}
      </Select>
      <span style={{ margin: "0 1rem" }}>by</span>
      {/* <Select
        disabled={true}
        className="sort_options"
        defaultValue={byHours ? "hour" : "day"}
        style={{ width: 100 }}
      >
        <Option value="hour">Hour</Option>
        <Option value="day">Day</Option>
      </Select> */}
      <span style={{ margin: "0 1rem" }}>show top</span>
      <Select
        className="sort_options"
        defaultValue={5}
        onSelect={onSelectTop}
        style={{ width: 100 }}
      >
        <Option value={3}>3</Option>
        <Option value={5}>5</Option>
        <Option value={10}>10</Option>
      </Select>
      <Spin spinning={isLoading}>
        {!isLoading && (
          <Bar options={{}} data={chartData} />
          // <Line
          //   options={{
          //     legend: {
          //       position: "bottom",
          //       align: "center",
          //       labels: {
          //         padding: 15,
          //         boxWidth: 10,
          //       },
          //     },
          //     tooltips: {
          //       mode: "index",
          //       backgroundColor: "#2b71ae",
          //       bodySpacing: 6,
          //       position: "average",
          //       itemSort: (a, b) => b.yLabel - a.yLabel,
          //       callbacks: {
          //         title: (tooltipItem) =>
          //           `${capitalizeFirstLetter(sortType)} for ${
          //             tooltipItem[0].label
          //           }`,
          //         labelColor: (tooltipItem, chart) => {
          //           let color =
          //             chart.config.data.datasets[tooltipItem.datasetIndex]
          //               .backgroundColor;
          //           return {
          //             borderColor: color,
          //             backgroundColor: color,
          //           };
          //         },
          //         label: (tool, data) =>
          //           `${
          //             type === "cash"
          //               ? formatCurrency(tool.yLabel)
          //               : millify(tool.yLabel)
          //           } ~ ${data.datasets[tool.datasetIndex].label}`,
          //       },
          //     },
          //     animation: {
          //       duration: 500,
          //     },
          //     layout: {
          //       padding: {
          //         left: 0,
          //         right: 0,
          //         top: 0,
          //         bottom: 30,
          //       },
          //     },
          //     scales: {
          //       yAxes: [
          //         {
          //           ticks: {
          //             callback: (value) =>
          //               type === "cash" ? `$${value}` : millify(value),
          //             autoSkip: true,
          //             maxTicksLimit: 5,
          //             fontStyle: "normal",
          //             beginAtZero: true,
          //             padding: 25,
          //           },
          //           gridLines: {
          //             display: false,
          //           },
          //         },
          //       ],
          //       xAxes: [
          //         {
          //           ticks: {
          //             autoSkip: true,
          //             maxTicksLimit: 12,
          //             fontSize: 12,
          //           },
          //           gridLines: {
          //             display: false,
          //           },
          //         },
          //       ],
          //     },
          //   }}
          //   data={chartData}
          // />
        )}
      </Spin>
    </div>
  );
};

export default ReportsCharts;
