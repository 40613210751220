import React from "react";
import { FetchOffers } from "../../../services/ApiHooks";
import moment from "moment";
import OfferCardWidget from "../../../components/Widgets/OfferCardWidget";
import { List, Spin } from "antd";
const Today = moment().format("YYYY-MM-DD");
const OffersPage = ({ props }) => {
  const { fromLink } = props.location;
  fromLink && window.location.reload();
  let query = {
    [`filters[Stat.date][values][]`]: [Today, Today],
    [`fields`]: [
      "Stat.payout",
      "Offer.name",
      "Stat.offer_id",
      "OfferIncentive.name",
    ],
    [`filters[Stat.affiliate_manager_id][conditional]`]: "EQUAL_TO",
    [`filters[Stat.affiliate_manager_id][values]`]: 72,
    [`filters[Stat.date][conditional]`]: "BETWEEN",
    [`sort[Stat.payout]`]: "desc",
    totals: 1,
    limit: 20,
  };
  const [data, isLoading, error] = FetchOffers(query);
  return (
    <div id="offers_page">
      {isLoading ? (
        <Spin> </Spin>
      ) : error ? (
        <div>Sorry please try again...</div>
      ) : (
        <List
          grid={{
            gutter: 8,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 3,
            xxl: 4,
          }}
          itemLayout="vertical"
          size="small"
          dataSource={data}
          renderItem={(item) => <OfferCardWidget offer={item} />}
        />
      )}
    </div>
  );
};

export default OffersPage;
