import React, { useState } from "react";
import { FetchOffer } from "../../services/ApiHooks";
import $ from "jquery";
import { List, Avatar, Card, Modal, Button, Input, message } from "antd";
import {
  LinkOutlined,
  FileImageOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getOfferCreatives } from "../../services/Firebase";
const generateLink = (aff_id, offer_id) =>
  `http://www.yourcontenhere.com/${aff_id}/${offer_id}`;
const OfferCardWidget = ({ offer }) => {
  const [affiliateID, setAffiliateID] = useState(null);
  let query = {
    id: offer.Stat.offer_id,
    [`fields`]: ["preview_url", "name", "description"],
    [`contain`]: ["Thumbnail", "Stat", "OfferCategory"],
  };
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showCreativeModal, setShowCreativeModal] = useState(false);
  const [creatives, setCreatives] = useState(null);
  const [data, isLoading] = FetchOffer(query);
  const greCreatives = async () => {
    setShowCreativeModal(true);
    try {
      let creatives = await getOfferCreatives(query.id);
      if (creatives.data()) {
        setCreatives(creatives.data().creatives);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      {!isLoading && data.OfferCategory[37] ? (
        <List.Item>
          <Card
            actions={[
              <LinkOutlined
                onClick={() => {
                  setShowLinkModal(true);
                }}
              />,
              <FileImageOutlined onClick={() => greCreatives()} />,
            ]}
          >
            <Card.Meta
              avatar={<Avatar src={data.Thumbnail.url} />}
              title={data.Offer.name}
              description={data.Offer.description}
            />
          </Card>
          <Modal
            visible={showLinkModal}
            title="Generate affiliate Link"
            onCancel={() => {
              setAffiliateID(null);
              setShowLinkModal(false);
            }}
            footer={[
              <CopyToClipboard
                key="url"
                onCopy={(text) => {
                  navigator.clipboard.writeText(text);
                  message.success("Copied to clipboard", 1.5).then(() => {
                    setAffiliateID(null);
                    setShowLinkModal(false);
                  });
                }}
                text={generateLink(affiliateID, offer.Stat.offer_id)}
              >
                <Button
                  disabled={affiliateID ? false : true}
                  key="copy"
                  type="primary"
                >
                  COPY LINK
                </Button>
              </CopyToClipboard>,
            ]}
          >
            <Input
              style={{ marginBottom: "1rem" }}
              onChange={(e) => setAffiliateID(e.target.value)}
              placeholder="Enter Affiliate ID"
            />
            {affiliateID && generateLink(affiliateID, offer.Stat.offer_id)}
          </Modal>
          <Modal
            visible={showCreativeModal}
            title="Creatives"
            onCancel={() => {
              setAffiliateID(null);
              setShowCreativeModal(false);
              setCreatives(null);
            }}
            footer={[]}
          >
            {creatives === null
              ? "Sorry no creatives found"
              : creatives.map((creative, key) => {
                  return (
                    <Card
                      className={`card-class-creative-preview-${key}`}
                      style={{ margin: "20px 0" }}
                      key={key}
                      actions={[
                        <DownloadOutlined
                          onClick={() =>
                            window.open(
                              `https://kast-static.s3.amazonaws.com/creatives/espire/${creative.source}`,
                              "_blank"
                            )
                          }
                        />,
                      ]}
                      cover={
                        <img
                          onError={() => {
                            $(`.card-class-creative-preview-${key}`).hide();
                            console.log("error loading", key);
                          }}
                          alt="example"
                          src={`https://kast-static.s3.amazonaws.com/creatives/espire/preview/${creative.preview}`}
                        />
                      }
                    ></Card>
                  );
                })}
          </Modal>
        </List.Item>
      ) : (
        <div style={{ display: "none" }}>none</div>
      )}
    </div>
  );
};

export default OfferCardWidget;
