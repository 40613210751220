import React, { useState } from "react";
import * as Firestore from "../../services/Firebase";

import { Form, Input, Button, Checkbox } from "antd";
import Backend from "../../services/Backend";
import Authenticate from "../../services/Authenticate";
import { authAction } from "../../state/actions/AuthAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Sentry from "@sentry/browser";

function Login({ authAction }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setError(null);
    setLoading(true);
    try {
      let response = await Authenticate(values);
      authAction(response, "SET_AUTH_HASOFFERS");
      let registerWithServer = await Backend.registerLoginWithServer(response);
      let register_with = {
        ...registerWithServer,
        profile: {
          ...registerWithServer.profile,
          advertiserId: null,
          affiliateId: 1166,
          email: "horoscopicespire@gmail.com",
          id: 1166,
          requireScreenshots: false,
          uid: 1166,
          username: "affiliate125652t",
        },
      };
      await Firestore.signInWithCustomToken(register_with.firebaseToken);
      authAction(register_with, "SET_AUTH");
    } catch (error) {
      console.log("error", error);
      setError("Please try again later");
      Sentry.captureException(error);
      setLoading(false);
    }
  };

  return (
    <div className='form-container'>
      <Form className='main-form ' {...layout} name='basic' initialValues={{ remember: true }} onFinish={onFinish}>
        <h2 className='form-header-message'>Welcome back!</h2>
        <Form.Item label='Email' name='email' rules={[{ required: true, message: "Please input your email!" }]}>
          <Input />
        </Form.Item>
        <Form.Item label='Password' name='password' rules={[{ required: true, message: "Please input yout password!" }]}>
          <Input.Password />
        </Form.Item>
        {error && <Form.Item className='login-error'>{error}</Form.Item>}
        <Form.Item {...tailLayout} name='remember' valuePropName='checked'>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button loading={loading} type='primary' htmlType='submit'>
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) => bindActionCreators({ authAction }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Login);

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};
