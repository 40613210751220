import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PageHeader, Col, Row, message } from "antd";
import { Tag, List, Skeleton } from "antd";
import HasOffersAPI from "../../../services/HasOffersAPI";
import { authAction } from "../../../state/actions/AuthAction";
import { reportToSentry } from "../../../util";
import EditFieldModal from "../../../components/EditFieldModal";
function Profile(props) {
  const Employee = HasOffersAPI.employee;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [profile] = useState(props.auth.profile);
  const [affiliate, setAffiliate] = useState(props.hasoffers_profile);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState({
    field: null,
    title: null,
    key: null,
  });

  const onFinish = async (values) => {
    try {
      setConfirmLoading(true);
      let updated = await Employee.updateField(profile.id, modalData.field, values[`${modalData.key}`]);
      props.authAction(updated.employee, "SET_AUTH_HASOFFERS");
      setAffiliate(updated.employee);
      setConfirmLoading(false);
      setModalVisible(false);
    } catch (error) {
      setModalVisible(false);
      setConfirmLoading(false);
      message.error({
        content: "Sorry please try again later...",
        key: "updating_profile",
        duration: 1,
      });
      reportToSentry(["api", `Error updating has offers profile`, "HasOffersAPI"]);
    }
  };

  const list = [
    {
      field: "email",
      title: "Email",
      key: "email",
      value: affiliate.email,
      disabled: true,
    },
    {
      field: "first_name",
      title: "First Name",
      key: "firstName",
      value: affiliate.firstName,
      disabled: false,
    },
    {
      field: "last_name",
      title: "Last Name",
      key: "lastName",
      value: affiliate.lastName,
      disabled: false,
    },
  ];

  return (
    <div className="profile_page">
      <PageHeader
        title={`@${profile.username}`}
        className="site-page-header"
        tags={<Tag color="green">ID #{affiliate.id}</Tag>}
        avatar={{
          shape: "circle",
          icon: <span>{profile.username.substring(0, 1).toUpperCase()}</span>,
        }}>
        <List
          style={{ maxWidth: "100%" }}
          itemLayout="horizontal"
          dataSource={list}
          renderItem={(item) => (
            <List.Item
            // actions={[
            //   <Button
            //     type="link"
            //     disabled={item.disabled}
            //     onClick={() => showModal(item)}
            //     key="list-loadmore-edit"
            //   >
            //     Edit
            //   </Button>,
            // ]}
            >
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  title={
                    <Row>
                      <Col span={4}>{item.title}</Col>
                      <Col span={20} style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                        {item.value}
                      </Col>
                    </Row>
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
      </PageHeader>
      {modalVisible && <EditFieldModal onFinish={onFinish} api={Employee} id={profile.id} modalData={modalData} visible={modalVisible} confirmLoading={confirmLoading} setModalVisible={setModalVisible} />}
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  hasoffers_profile: state.hasoffers_profile,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ authAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
