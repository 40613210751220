import React, { useState, useEffect } from "react";
import moment from "moment";
import { PageHeader, List, Button, Tag, Input, Row, Col, Spin } from "antd";
import ProfileDrawer from "./ProfileDrawer";
import HasOffersAPI from "../../../services/HasOffersAPI";
import InfiniteScroll from "react-infinite-scroll-component";
import "./AffiliatePage.scss";
import { reportToSentry } from "../../../util";

const { Search } = Input;

function ManageAffiliates() {
  const Affiliate = HasOffersAPI.affiliate;

  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [hasMore, sethasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerUser, setDrawerUser] = useState(null);
  const fetchAffiliates = async (query) => {
    return new Promise((resolve, reject) => {
      Affiliate.findAffiliates(query, false)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  };
  const fetchAll = async (query) => {
    try {
      let page = await fetchAffiliates(query);
      initLoading && setPageCount(page.pageCount);
      setData(page);
      setInitLoading(false);
      setLoading(false);
    } catch (error) {
      setData([]);
      setDrawerUser(null);
      setInitLoading(false);
      reportToSentry([
        "api",
        `Error fetching manage affiliate list`,
        "HasOffersAPI",
      ]);
    }
  };
  useEffect(() => {
    let query = {
      [`filters[account_manager_id]`]: 72,
      [`fields[]`]: "status",
      limit: 100,
      page: currentPage,
      [`sort[Affiliate.id]`]: "asc",
      [`contain[]`]: "AffiliateUser",
    };
    fetchAll(query);
  }, []);
  const fetchMoreData = async () => {
    setLoading(true);
    if (currentPage === pageCount) {
      sethasMore(false);
      setLoading(false);
    } else {
      let page = await fetchAffiliates({
        [`filters[account_manager_id]`]: 72,
        [`fields[]`]: "status",
        limit: 100,
        page: currentPage + 1,
        [`sort[Affiliate.id]`]: "asc",
        [`contain[]`]: "AffiliateUser",
      });
      let newData = data.concat(page);
      initLoading && setPageCount(page.pageCount);
      setCurrentPage(currentPage + 1);
      setData(newData);
      setLoading(false);
      reportToSentry([
        "api",
        `Error fetching more pages in manage affiliate list`,
        "HasOffersAPI",
      ]);
    }
  };

  const onSearch = async (affiliate_id) => {
    setLoading(true);
    setCurrentData(data);
    setData([]);
    try {
      let affiliate = await Affiliate.findAffiliateAffiliateUser({
        id: affiliate_id,
        [`contain[]`]: "AffiliateUser",
      });
      if (!affiliate) {
        throw new Error("No User Found");
      }
      setData(affiliate);
      setLoading(false);
    } catch (error) {
      setData([]);
      setDrawerUser(null);
      setLoading(false);
      reportToSentry([
        "api",
        `Error seaching user in managed affiliates`,
        "HasOffersAPI",
      ]);
    }
  };
  const onChange = async (e) => {
    let { value } = e.target;
    if (value.length < 1) {
      setData(currentData);
      setDrawerUser(null);
    } else {
    }
  };
  const toggleDrawer = async (aff) => {
    try {
      setDrawerUser(aff);
    } catch (error) {
      setDrawerUser(null);
    } finally {
      setShowDrawer(!showDrawer);
    }
  };
  return (
    <div className="affiliate_page">
      <PageHeader
        className="main_page_header"
        extra={
          <Search
            className="search_input"
            placeholder="Search Affiliate ID"
            onSearch={(value) => onSearch(value)}
            onChange={(value) => onChange(value)}
          />
        }
        title={`Managed Affiliates`}
      >
        <InfiniteScroll
          dataLength={data.length}
          // next={fetchMoreData}
          hasMore={hasMore}
          scrollableTarget="scrollableDiv"
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>No more affiliates found</b>
            </p>
          }
        >
          <Row className="ant-list-header">
            <Col xs={0} sm={0} md={3} lg={3} xl={3}>
              ID
            </Col>
            <Col xs={0} sm={0} md={8} lg={8} xl={8}>
              Full Name
            </Col>
            <Col xs={0} sm={0} md={8} lg={8} xl={8}>
              Email
            </Col>
            <Col xs={0} sm={0} md={3} lg={3} xl={3}>
              Last Logged In
            </Col>
            <Col xs={0} sm={0} md={2} lg={2} xl={2} />
          </Row>
          <Spin spinning={loading}>
            <div id="scrollableDiv">
              <List
                loading={initLoading}
                itemLayout="horizontal"
                dataSource={data}
                loadMore={
                  !initLoading && !loading ? (
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: 12,
                        height: 32,
                        lineHeight: "32px",
                      }}
                    >
                      <Button onClick={fetchMoreData}>Load more</Button>
                    </div>
                  ) : null
                }
                renderItem={(aff) => {
                  let item = aff.AffiliateUser;
                  return (
                    <>
                      <Row>
                        <Col xs={24} sm={24} md={0} lg={0} xl={0}>
                          <List.Item
                            actions={[
                              <Button
                                onClick={() => toggleDrawer(aff)}
                                type="link"
                                key="list-loadmore-edit"
                              >
                                Edit
                              </Button>,
                            ]}
                          >
                            <List.Item.Meta
                              title={
                                <div>
                                  <Tag
                                    color={
                                      item.status === "active" ? "green" : "red"
                                    }
                                  >
                                    #{item.affiliate_id}
                                  </Tag>
                                  {`${item.first_name} ${item.last_name}`}
                                </div>
                              }
                              description={item.email}
                            />
                          </List.Item>
                          <div />
                        </Col>
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}>
                          <Tag
                            color={item.status === "active" ? "green" : "red"}
                          >
                            #{item.affiliate_id}
                          </Tag>
                        </Col>
                        <Col xs={0} sm={0} md={8} lg={8} xl={8}>
                          {`${item.first_name} ${item.last_name}`}
                        </Col>
                        <Col xs={0} sm={0} md={8} lg={8} xl={8}>
                          {item.email}
                        </Col>
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}>
                          {item.last_login &&
                            moment(item.last_login).format("M/DD/YY")}
                        </Col>
                        <Col xs={0} sm={0} md={2} lg={2} xl={2}>
                          <Button
                            onClick={() => toggleDrawer(aff)}
                            type="link"
                            style={{ float: "right" }}
                            key="list-loadmore-edit"
                          >
                            Edit
                          </Button>
                        </Col>
                      </Row>
                    </>
                  );
                }}
              />
            </div>
          </Spin>
        </InfiniteScroll>
      </PageHeader>
      {showDrawer && (
        <ProfileDrawer
          user={drawerUser}
          toggleDrawer={toggleDrawer}
          showDrawer={showDrawer}
        />
      )}
    </div>
  );
}

export default ManageAffiliates;
