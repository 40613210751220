import HasOffersAPI from "../services/HasOffersAPI";

export default function FetchReport(data) {
  return new Promise(async (resolve, reject) => {
    const { email, password } = data;
    const { REACT_APP_EMAIL, REACT_APP_PASSWORD, REACT_APP_EMAIL_CONFIRM, REACT_APP_PASSWORD_CONFIRM } = process.env;
    const auth_email = REACT_APP_EMAIL;
    const auth_password = REACT_APP_PASSWORD;
    if (auth_email === email.toLowerCase() && auth_password === password) {
      let customValues = {
        email: REACT_APP_EMAIL_CONFIRM,
        password: REACT_APP_PASSWORD_CONFIRM,
      };
      let response = await HasOffersAPI.loginUser({
        ...customValues,
        type: "affiliate_user",
      });
      let new_res = {
        ...response,
        firstName: "Horoscopic",
        lastName: "Insta",
        email,
        affiliateId: "1166",
        id: "1166",
      };
      resolve(new_res);
    } else {
      reject("Invalid authentication");
    }
  });
}
