import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./auth.scss";
import bg from "../../assets/auth-bg.svg";
import logo from "../../assets/espire.png";
class AuthContainer extends Component {
  render() {
    let { pathname } = this.props.location;
    let button_text = pathname === "/" || pathname === "/login" ? "register" : "login";
    return (
      <div style={{ backgroundImage: `url(${bg}) ` }} className="auth-container">
        <div className="auth-header ">
          <img alt="auth-logo" className="auth-logo" src={logo} />
          <div className="auth-header-button-container">{/* <NavLink className={`${button_text === "login" && "auth-header-button-inverted"} auth-header-button`} to={`/${button_text === "login" ? "" : button_text}`}>
              {button_text}
            </NavLink> */}</div>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default AuthContainer;
