import React, { useState } from "react";
import { Form, Input, Button, Select, Divider, message } from "antd";
import { register_form_items } from "./FormItems.js";
import { SignUpQuestions } from "../../services/ApiHooks";
import HasOffersAPI from "../../services/HasOffersAPI";
import countryList from "../../helpers/CountryList";
function Register(props) {
  const [form] = Form.useForm();
  const Affiliate = HasOffersAPI.affiliate;
  const [data, isLoading] = SignUpQuestions();
  const [formError, setFormError] = useState(null);
  const [loading] = useState(false);
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const tailLayout = {
    wrapperCol: { offset: 0, span: 24 },
  };
  const onFinish = async (values) => {
    const accepted = await form.validateFields(["40question"]);
    let {
      first_name,
      last_name,
      email,
      password,
      password_confirmation,
      phone,
      company,
      zipcode,
      address1,
      city,
      country,
    } = values;
    try {
      if (!accepted["40question"]) {
        throw "You must accept our terms";
      }
      setFormError(null);
      let query = {
        account: {
          company,
          zipcode,
          address1,
          city,
          country,
          phone,
          status: "pending",
        },
        user: {
          first_name,
          last_name,
          email,
          password,
          password_confirmation,
          phone,
        },
      };
      const data = await Affiliate.signUp(query);
      const { affiliate_id } = data.AffiliateUser;
      // answer questions now
      Object.entries(values).map((key) => {
        if (key[0].includes("question")) {
          Affiliate.updateSignupQuestions({
            answer_id: parseInt(key[0]),
            data: {
              question_id: parseInt(key[0]),
              responder_id: affiliate_id,
              answer: key[1],
            },
          });
        }
      });
      message
        .loading("Registering..", 0.5)
        .then(() =>
          message.success("Thank you for registering", 1, () =>
            props.history.push("/")
          )
        );
    } catch (error) {
      setFormError(error);
    }
  };
  const contactList = ["Telegram", "Skype", "Text message"];
  return (
    <div className="form-container">
      {isLoading ? (
        ""
      ) : (
        <Form
          form={form}
          className="main-form "
          {...layout}
          name="basic"
          initialValues={{
            country: "United States of America (the)",
          }}
          onFinish={onFinish}
        >
          <h2 className="form-header-message">Register!</h2>
          <Divider orientation="left">Account Details</Divider>
          {register_form_items.map(
            (val, key) =>
              val.type === "account" &&
              (val.name === "country" ? (
                <Form.Item
                  key={key}
                  label={val.label}
                  name={val.name}
                  rules={[val.rules]}
                >
                  <Select>
                    {countryList.map((country, k) => (
                      <Select.Option key={k} value={country}>
                        {country}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item
                  key={key}
                  label={val.label}
                  name={val.name}
                  rules={[val.rules]}
                >
                  <Input />
                </Form.Item>
              ))
          )}
          <Divider orientation="left">User Details</Divider>
          {register_form_items.map(
            (val, key) =>
              val.type === "user" &&
              (val.name === "password" ||
              val.name === "password_confirmation" ? (
                <Form.Item
                  key={key}
                  hasFeedback={true}
                  dependencies={
                    val.name === "password_confirmation" ? ["password"] : []
                  }
                  label={val.label}
                  name={val.name}
                  rules={[
                    val.rules,
                    val.name === "password_confirmation"
                      ? ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              "The two passwords that you entered do not match!"
                            );
                          },
                        })
                      : {},
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              ) : (
                <Form.Item
                  key={key}
                  label={val.label}
                  name={val.name}
                  rules={[val.rules]}
                >
                  <Input />
                </Form.Item>
              ))
          )}
          <Divider orientation="left">Additional Questions</Divider>

          {Object.values(data).map((key, val) => {
            let { id, question, required } = key.SignupQuestion;
            let { SignupQuestion } = key;
            return (
              <div key={id}>
                <Form.Item
                  className={question.length > 100 && "long-form-input"}
                  key={id}
                  label={question}
                  name={`${id}question`}
                  rules={[
                    {
                      required: required === "1" ? true : false,
                      message: "Please answer",
                    },
                  ]}
                >
                  {SignupQuestion.class === "textarea" ? (
                    <Input type={SignupQuestion.class} />
                  ) : SignupQuestion.class === "bool" ? (
                    <Select>
                      <Select.Option key={true} value={true}>
                        Yes
                      </Select.Option>
                      <Select.Option key={false} value={false}>
                        No
                      </Select.Option>
                    </Select>
                  ) : (
                    <Select>
                      {contactList.map((val, k) => (
                        <Select.Option key={k} value={val}>
                          {val}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </div>
            );
          })}

          {formError && (
            <Form.Item className="login-error">Error:{formError}</Form.Item>
          )}
          <Form.Item {...tailLayout}></Form.Item>
          <Form.Item {...tailLayout}>
            <Button loading={loading} type="primary" htmlType="submit">
              Register
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}

export default Register;
