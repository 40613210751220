import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./state";
import "./styles/index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";
import GitInfo from "react-git-info/macro";
const gitInfo = GitInfo();

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_URL}`,
  release: gitInfo.commit.shortHash,
  beforeSend: (event, hint) => {
    setTimeout(() => console.error(hint.originalException || hint.syntheticException), 0);
    return event;
  },
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App store={store} />
    </PersistGate>
  </Provider>,

  document.getElementById("root"),
);
serviceWorker.register();
