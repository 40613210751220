import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col } from "antd";
import Performance from "../../../components/Widgets/Performance";
import AccountTotals from "../../../components/Widgets/AccountTotals";
import TopOffers from "../../../components/Widgets/TopOffers";
import SignupLinks from "../../../components/Widgets/SignupLinks";
import "../../../components/Widgets/widgets.scss";

function Home(props) {
  return (
    <div id="home-page">
      <Row gutter={[8, 16]}>
        <Col style={{ display: "flex", flexDirection: "column" }} xs={24} sm={24} md={8} lg={9} xl={8}>
          <Row>
            <AccountTotals auth={props.auth} />
          </Row>
          <Row style={{ marginTop: "8px" }}>
            <TopOffers auth={props.auth} />
          </Row>
        </Col>
        <Col xs={24} sm={24} md={16} lg={15} xl={16}>
          <Performance auth={props.auth} />
          <Row
            style={{
              marginTop: "8px",
            }}>
            <SignupLinks />
          </Row>
        </Col>
      </Row>
    </div>
  );
}
const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Home);
