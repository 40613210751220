import React, { useState } from "react";
import { Drawer, message, Divider } from "antd";
import { Button } from "antd";
import { Row, Col, Form, Input, List, Skeleton } from "antd";
import HasOffersAPI from "../../../services/HasOffersAPI";
import { reportToSentry } from "../../../util";

import "./ProfileDrawer.scss";

const ProfileDrawer = ({ user, showDrawer, toggleDrawer }) => {
  const [currentlyEditing, setCurrentlyEditing] = useState({});
  const [affiliateUser] = useState(user && user.AffiliateUser);
  const [list, setList] = useState([
    {
      field: "email",
      title: "Email",
      key: "email",
      value: affiliateUser.email,
      disabled: true,
    },
    {
      field: "first_name",
      title: "First Name",
      key: "first_name",
      value: affiliateUser.first_name,
      disabled: false,
    },
    {
      field: "last_name",
      title: "Last Name",
      key: "last_name",
      value: affiliateUser.last_name,
      disabled: false,
    },
  ]);
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    toggleDrawer();
  };
  const updateUser = async (affiliateId, field, value) => {
    return new Promise((resolve, reject) => {
      HasOffersAPI.affiliateUser
        .updateField(affiliateId, field, value)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  };
  const refreshList = (values) => {
    const foundIndex = list.findIndex(
      (element) => element.key === Object.keys(values)[0]
    );
    list[foundIndex].value = values[Object.keys(values)[0]];
    setList(list);
    setLoading(false);
    setCurrentlyEditing({});
  };
  const onFinish = async (values) => {
    setLoading(true);
    try {
      await updateUser(
        affiliateUser.id,
        Object.keys(values)[0],
        values[Object.keys(values)[0]]
      );
      refreshList(values);
      message.success({
        content: "Profile Updated",
        duration: 1,
      });
    } catch (error) {
      message.error({
        content: "Sorry please try again later...",
        key: "updating_affiliateUser_profile",
        duration: 1,
      });
      reportToSentry([
        "api",
        `Error updating has offers profile`,
        "HasOffersAPI",
      ]);
    }
  };
  const editingField = (item) => {
    setLoading(false);
    setCurrentlyEditing(item);
  };

  return (
    <div className="profileDrawer">
      <Drawer
        placement="right"
        closable={true}
        onClose={onClose}
        visible={showDrawer}
      >
        {user && (
          <>
            <Divider orientation="left">Affiliate User</Divider>
            <List
              style={{ maxWidth: "100%" }}
              itemLayout="horizontal"
              dataSource={list}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    currentlyEditing.key === item.key && (
                      <Button
                        className="action_button"
                        type="link"
                        onClick={() => setCurrentlyEditing({})}
                        disabled={item.disabled}
                      >
                        Cancel
                      </Button>
                    ),
                    currentlyEditing.key === item.key ? (
                      <Button
                        className="action_button"
                        form="submitForm"
                        key="submit"
                        htmlType="submit"
                        type="link"
                        loading={
                          currentlyEditing.key === item.key ? loading : false
                        }
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        className="action_button"
                        type="link"
                        onClick={() => editingField(item)}
                        disabled={item.disabled}
                      >
                        Edit
                      </Button>
                    ),
                  ]}
                >
                  <Skeleton avatar title={false} loading={item.loading} active>
                    <List.Item.Meta
                      title={
                        <Row>
                          <Col span={12}>{item.title}</Col>
                          <Col
                            span={12}
                            style={{ color: "rgba(0, 0, 0, 0.45)" }}
                          >
                            {currentlyEditing.key === item.key ? (
                              <Form
                                id="submitForm"
                                className="editUserForm"
                                onFinish={onFinish}
                              >
                                <Form.Item
                                  style={{ marginBottom: 0 }}
                                  initialValue={item.value}
                                  name={item.key}
                                >
                                  <Input size="small" name={`${item.key}`} />
                                </Form.Item>
                              </Form>
                            ) : (
                              item.value
                            )}
                          </Col>
                        </Row>
                      }
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </>
        )}
      </Drawer>
    </div>
  );
};
export default ProfileDrawer;
