import React from "react";
import { PageHeader, Tag, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

const SignupLinks = () => {
  return (
    <PageHeader
      className="main_page_header"
      style={{ width: "100%" }}
      title={`Signup Links Offers`}
    >
      Recruit partners with the link below. Successful signups will have their
      account automatically approved and you assigned as their account manager.
      <br />
      <CopyToClipboard
        onCopy={(text) => {
          navigator.clipboard.writeText(text);
          message.success("Copied to clipboard", 1);
        }}
        text={" http://influence.espireads.com/signup_affiliate/22"}
      >
        <Tag
          id="signUpLink"
          style={{
            fontSize: "1rem",
            cursor: "pointer",
            margin: "1rem 0",
            padding: "8px",
          }}
          icon={<CopyOutlined />}
          color="#2b71ae"
        >
          http://influence.espireads.com/signup_affiliate/22
        </Tag>
      </CopyToClipboard>
    </PageHeader>
  );
};
export default SignupLinks;
